import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'

type NavState = {
  showNavMenu: boolean
  showHeaderMenu: boolean
  collapseNavMenu: boolean
}

const initialState: NavState = {
  showNavMenu: false,
  showHeaderMenu: false,
  collapseNavMenu: false,
}

export const navReducer = createSlice({
  name: 'navReducer',
  initialState,
  reducers: {
    setShowHeaderMenuAction: (state, { payload: showHeaderMenu }: PayloadAction<boolean>) => {
      state.showHeaderMenu = showHeaderMenu
      state.showNavMenu = false
    },
    setShowNavMenuAction: (state, { payload: showNavMenu }: PayloadAction<boolean>) => {
      state.showNavMenu = showNavMenu
      state.showHeaderMenu = false
    },
    setCollapseNavMenuAction: (state) => {
      state.collapseNavMenu = !state.collapseNavMenu
    },
  },
})

const { setShowHeaderMenuAction, setShowNavMenuAction, setCollapseNavMenuAction } =
  navReducer.actions

export const setShowNavMenu = (showNavMenu: boolean) => (dispatch: Dispatch) =>
  dispatch(setShowNavMenuAction(showNavMenu))

export const setShowHeaderMenu = (showHeaderMenu: boolean) => (dispatch: Dispatch) =>
  dispatch(setShowHeaderMenuAction(showHeaderMenu))

export const setCollapseNavMenu = () => (dispatch: Dispatch) => dispatch(setCollapseNavMenuAction())

export default navReducer.reducer
