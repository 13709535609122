import React, { useEffect, useState, useCallback, useMemo } from 'react'
import _map from 'lodash/map'
import _find from 'lodash/find'
import _filter from 'lodash/filter'
import _sortBy from 'lodash/sortBy'
import cn from 'classnames'
import { Button } from 'application/presentation/common/uiComponents'
import queryClient from 'application/data/apiClient/queryClient'
import { addClientConfig } from 'application/domain/useCase/clientConfigs/clientConfigsAction'
import ToastNotify from 'application/app/ToastNotify/ToastNotify'
import carsDB from 'application/data/localData/cars.json'
import { ReactComponent as BackSvg } from 'images/icons/long_left_arrow.svg'
import { generateCarModel } from 'application/domain/useCase/car/carActions'

import { UniversalInputComponent } from '../InputComponents'

import s from './SelectCarForm.module.scss'

type Props = {
  onSuccess: Function
  clientId: number
}

const topCarIds = [
  'VAZ',
  'AUDI',
  'BMW',
  'CHANGAN',
  'CHERY',
  'CHEVROLET',
  'DAEWOO',
  'CHERYEXEED',
  'FORD',
  'GEELY',
  'HAVAL',
  'HONDA',
  'HYUNDAI',
  'JETOUR',
  'KIA',
  'LAND_ROVER',
  'LEXUS',
  'MAZDA',
  'MERCEDES',
  'MITSUBISHI',
  'NISSAN',
  'OMODA',
  'OPEL',
  'PEUGEOT',
  'PORSCHE',
  'RENAULT',
  'SKODA',
  'SUBARU',
  'SUZUKI',
  'TOYOTA',
  'VOLKSWAGEN',
  'VOLVO',
  'GAZ',
  'UAZ',
]

const SelectCarForm = ({ onSuccess, clientId }: Props) => {
  const [carsList, setCarsList] = useState<any>([])
  const [carsModelsList, setCarsModelsList] = useState<any>([])
  const [selectedCarId, setSelectedCarId] = useState<string | number>(0)
  const [selectedCarModelId, setSelectedCarModelId] = useState<string | number>(0)
  const [showAllModels, setShowAllModels] = useState<boolean>(false)

  const [saveIsLoading, setSaveIsLoading] = useState<boolean>(false)

  const [carNumber, setCarNumber] = useState<string>('')
  const [carRegion, setCarRegion] = useState<string>('')

  const savePressHandler = useCallback(() => {
    setSaveIsLoading(true)
    const carModel = generateCarModel({
      carId: selectedCarId,
      modelId: selectedCarModelId,
      carNumber: `${carNumber}${carRegion}`,
    })
    const car = JSON.stringify(carModel)
    addClientConfig(clientId, car)
      .then((data: any) => {
        queryClient.invalidateQueries(['client_configs']).then(() => {
          setSaveIsLoading(false)
          if (data) {
            onSuccess(data.id)
          }
          // setCar(carModel)(dispatch)
        })
      })
      .catch(() => {
        ToastNotify('Не удалось сохранить конфигурацию')
        setSaveIsLoading(false)
      })
  }, [selectedCarId, selectedCarModelId, carNumber, carRegion, clientId, onSuccess])

  const topCars = useMemo(
    () =>
      _sortBy(
        showAllModels ? carsDB : _filter(carsDB, (item) => topCarIds.indexOf(item.id) !== -1),
        'name',
      ),
    [showAllModels],
  )

  const topCarsModels = useMemo(() => {
    const car = _find(carsDB, (item) => item.id === selectedCarId)
    return car ? car.models : []
  }, [selectedCarId])

  useEffect(() => {
    setCarsList(
      _map(carsDB, (item: any) => ({
        id: item.id,
        label: item.name,
        value: item.id,
      })),
    )
  }, [])

  useEffect(() => {
    if (selectedCarId) {
      const car = _find(carsDB, (item) => item.id === selectedCarId)
      if (car) {
        const list = _map(car.models, (item) => ({
          id: item.id,
          label: `${item.name}`,
          value: item.id,
          data: item,
        }))
        setCarsModelsList(list)
      } else {
        setCarsModelsList([])
      }
    }
  }, [selectedCarId])

  return (
    <div>
      <div className={s.top_row}>
        <UniversalInputComponent
          type="selector"
          options={carsList}
          value={selectedCarId}
          onChange={setSelectedCarId}
          placeholder="Марка машины"
        />
        <UniversalInputComponent
          type="selector"
          options={carsModelsList}
          value={selectedCarModelId}
          onChange={setSelectedCarModelId}
          placeholder="Модель"
        />
        <UniversalInputComponent
          type="masked_text"
          mask="r999rr"
          value={carNumber}
          onChange={(val: string) => setCarNumber(val.toUpperCase())}
          maskChar={'_'}
          placeholder="Номер"
        />
        <UniversalInputComponent
          type="text"
          placeholder="Регион"
          value={carRegion}
          onChange={setCarRegion}
        />
        <Button
          color="fiol"
          text="Далее"
          onClick={savePressHandler}
          containerClassName={s.btn}
          isLoading={saveIsLoading}
          disabled={!selectedCarId}
        />
      </div>
      {!selectedCarId ? (
        <div className={s.bottom_container}>
          <div className={s.title}>Марка</div>
          <div className={s.items}>
            {topCars.map((item) => (
              <div className={s.item} onClick={() => setSelectedCarId(item.id)}>
                {item.name}
              </div>
            ))}
            {!showAllModels && (
              <div className={cn(s.item, s.last)} onClick={() => setShowAllModels(true)}>
                Остальные
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className={s.bottom_container}>
          <div className={s.title}>
            <div
              onClick={() => {
                setSelectedCarId(0)
                setSelectedCarModelId(0)
              }}
            >
              <BackSvg />
            </div>
            Модель
          </div>
          <div className={s.items}>
            {topCarsModels.map((item) => (
              <div
                className={cn(s.item, { [s.active]: item.id === selectedCarModelId })}
                onClick={() => setSelectedCarModelId(item.id)}
              >
                {item.name}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default React.memo(SelectCarForm)
