import _ from 'lodash'
import React, { useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'

import { UniversalInputComponent } from '../../InputComponents'
import MWContainer from '../MWContainer'

import s from './HandBookMW.module.scss'

type Props = {
  data: any
}

const HandBookMW = ({ data }: Props) => {
  const { hash } = useLocation()
  const [searchVal, setSearchVal] = useState<string>('')
  const navigate = useNavigate()
  const opened = useMemo(() => {
    return hash.indexOf('hand_book') !== -1
  }, [hash])

  const searchOptions = useMemo(() => {
    if (data) {
      setSearchVal(data.groups[0].href)
      return _.map(data.groups, (item) => ({ label: item.name, value: item.href }))
    }
    return []
  }, [data])

  const filteredData = useMemo(() => {
    if (data) {
      const group = _.find(data.groups, { href: searchVal })
      return group ? group.productsHas : []
    }
    return []
  }, [searchVal, data])

  return (
    <MWContainer
      isOpen={opened}
      color="white"
      onRequestClose={() => {
        if (hash.indexOf('hand_book') !== -1) {
          navigate(-1)
        }
      }}
    >
      <div className={s.title}>Справочник</div>
      <UniversalInputComponent
        type="selector"
        value={searchVal}
        onChange={setSearchVal}
        options={searchOptions}
        containerClassName={s.search_container}
      />
      <div className={s.items_container}>
        {filteredData.map((item: any) => {
          return (
            <div className={s.row} key={`handbook_${item.href}`}>
              <div className={s.row_name}>{item.name}</div>
              <div className={s.row_value}>
                ({item.size} {item.measure})
              </div>
            </div>
          )
        })}
      </div>
    </MWContainer>
  )
}

export default React.memo(HandBookMW)
