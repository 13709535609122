import {
  EditAvailabilityProduct,
  RegionalPriceProduct,
  AddOptionMutationDataType,
} from 'application/domain/entity/products/products'

import apiClient from '../apiClient'

export const fetchProducts = async (outlet_id?: number) => {
  const { data } = await apiClient.get(
    `/api/personal/products${outlet_id ? `?outlet_id=${outlet_id}` : ''}`,
  )
  return data
}

export const fetchEditAvailabilityProduct = async (sendData: EditAvailabilityProduct) => {
  const { data } = await apiClient.post('/api/products/edit_availability', sendData)
  return data
}

export const fetchRegionalPriceProduct = async (sendData: RegionalPriceProduct) => {
  const { data } = await apiClient.post('/api/products/regional-price', sendData)
  return data
}

export const fetchCategories = async (outlet_id: number) => {
  const { data } = await apiClient.get(`/api/category?outlet_id=${outlet_id}`)
  return data
}

export const setClientRequest = async (phone: string) => {
  const { data } = await apiClient.post('/api/client/set', {
    phone,
  })
  return data
}

export const deleteOptionRequest = async (id: number) => {
  const { data } = await apiClient.delete(`/api/admin/products/item_to_variety/${id}`)

  return data
}

export const addOptionRequest = async (sendData: AddOptionMutationDataType) => {
  const { data } = await apiClient({
    method: 'POST',
    url: '/api/admin/products/edit_item_to_variety',
    data: sendData,
  })
  return data
}

export const regionalPriceRequest = async (
  region_id: number,
  amount: string,
  variety_id: number,
) => {
  const { data } = await apiClient.post('/api/products/regional-price', {
    region_id: region_id,
    amount: parseInt(amount, 10),
    variety_id: variety_id,
  })

  return data
}

export const fetchProduct = async (id: number, outlet_id?: number) => {
  if (id) {
    const { data } = await apiClient.get(
      `/api/products/${id}${outlet_id ? `?outlet_id=${outlet_id}` : ''}`,
    )
    return data
  }
  return undefined
}

export const updateProductStatusRequest = async (
  product_id: number,
  outlet_id: number,
  status: string,
  option_id?: number,
) => {
  const sendData: { outlet_id: number; status: string; variety_id?: number; product_id?: number } =
    {
      outlet_id,
      status,
    }

  if (option_id) {
    sendData.variety_id = option_id
    if (product_id !== 0) sendData.product_id = product_id
  } else {
    sendData.product_id = product_id
  }
  const { data } = await apiClient.post('/api/products/edit_availability', sendData)
  return data
}

export const fetchProductSelfcostDynamicRequest = async (sendData: Array<number>) => {
  const formData = new FormData()
  for (let i = 0; i < sendData.length; i++) {
    formData.append(`product_ids[${i}]`, `${sendData[i]}`)
  }
  const { data } = await apiClient.post(`/api/admin/varieties/averageCost?panel=true`, formData)
  return data
}
