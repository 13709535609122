import { configureStore } from '@reduxjs/toolkit'

import planReducer from './reducers/planAtomsSlice'
import basketSlice from './reducers/basketSlice'
import navSlice from './reducers/navSlice'
import userSlice from './reducers/userSlice'

const store = configureStore({
  reducer: {
    userReducer: userSlice,
    basketReducer: basketSlice,
    navReducer: navSlice,
    planReducer: planReducer,
  },
})

export default store

export type RootState = ReturnType<typeof store.getState>
