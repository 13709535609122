import _ from 'lodash'
import moment from 'moment'

import { FilterItemType } from './Filters'

export const checkIfHasValue = (item: FilterItemType) => {
  switch (item.type) {
    case 'selector':
      if (typeof item.value === 'string') {
        return !!item.value
      }
      return item.value >= 0
    case 'date_interval':
      return item.value.length && (item.value[0] || item.value[1]) ? true : false
    case 'input':
      return !!item.value
    default:
      return false
  }
}

export const getDateValues = (item: FilterItemType, prefix: string, format?: string) => {
  if (checkIfHasValue(item)) {
    return {
      [`${prefix}From`]: item.value[0]
        ? moment(item.value[0]).format(format || 'DD-MM-YYYY')
        : moment(0).format(format || 'DD-MM-YYYY'),
      [`${prefix}To`]: item.value[1]
        ? moment(item.value[1]).format(format || 'DD-MM-YYYY')
        : moment().format(format || 'DD-MM-YYYY'),
    }
  }

  // return {
  // [`${prefix}From`]: moment(0).format('DD-MM-YYYY'),
  // [`${prefix}To`]: moment().format('DD-MM-YYYY'),
  // }
  return {
    [`${prefix}From`]: '',
    [`${prefix}To`]: '',
  }
}

export const getLowerString = (val1: any, val2: any) => {
  if (
    typeof val1 === 'string' &&
    typeof val2 === 'string' &&
    val1.toLocaleLowerCase() == val2.toLocaleLowerCase()
  ) {
    return true
  }
  return false
}

export const getPartOfString = (val1: any, val2: any) => {
  if (
    typeof val1 === 'string' &&
    typeof val2 === 'string' &&
    val1.toLocaleLowerCase().indexOf(val2.toLocaleLowerCase()) !== -1
  ) {
    return true
  }
  return false
}

export const getFilterValues = (filterData: Array<FilterItemType>): { [key: string]: string } => {
  let obj: { [key: string]: string } = {}
  filterData.map((item) => {
    if (checkIfHasValue(item)) {
      obj[item.key] = item.value
    }
  })
  return obj
}

export const getFilteredData = async (data: Array<any>, filterData: Array<FilterItemType>) => {
  const filtered = _.filter(data, (item) => {
    let existed = true

    filterData.map((f_item) => {
      if ((f_item.type === 'input' || f_item.type === 'selector') && checkIfHasValue(f_item)) {
        if (getLowerString(item[f_item.key], f_item.value)) {
          // так как по дефолту existed = true, то тут ничего делать не надо
          // existed = true
        } else if (!f_item.strict_mode && getPartOfString(item[f_item.key], f_item.value)) {
          // так как по дефолту existed = true, то тут ничего делать не надо
          // existed = true
        } else if (item[f_item.key] === f_item.value) {
          // existed = false
        } else {
          existed = false
        }
      }
      if (f_item.type === 'date_interval' && checkIfHasValue(f_item)) {
        const interval = getDateValues(f_item, 'date', 'YYYY-MM-DD')
        const date = moment(item[f_item.key]).format('YYYY-MM-DD')
        existed =
          moment(date).isBetween(interval.dateFrom, interval.dateTo) ||
          moment(date).isSame(interval.dateFrom) ||
          moment(date).isSame(interval.dateTo)
      }
    })
    return existed
  })
  return Promise.resolve(filtered)
}
