import React, { useMemo } from 'react'
import { formatCarNumber } from 'application/utils/carUtils'
import cn from 'classnames'

import s from './ClientItem.module.scss'

type Props = {
  item: any
  onItemClick: Function
  isActive: boolean
}

const ClientItem = ({ item, onItemClick, isActive }: Props) => {
  const car = useMemo(() => {
    if (item.value) {
      const parsed = JSON.parse(item.value)
      return parsed
    }
    return null
  }, [item])
  return car ? (
    <div className={cn(s.container, { [s.active]: isActive })} onClick={() => onItemClick(item)}>
      <div className={s.left}>
        <div className={s.title}>
          {car ? (
            <>
              {car.name} {car.modelName}
              <br />
            </>
          ) : (
            ''
          )}

          {item.client.name}
        </div>
        <div className={s.subtitle}>{item.client.phone}</div>
      </div>
      <div className={s.right}>
        {car ? <div className={s.number}>{formatCarNumber(car)}</div> : <></>}
      </div>
    </div>
  ) : (
    <></>
  )
}

export default React.memo(ClientItem)
