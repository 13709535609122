import _ from 'lodash'
import React, { useMemo } from 'react'
import Select from 'react-select'

import './SelectWithSearch.scss'

type Props = {
  placeholder?: string
  options: Array<{ value: number; label: string }>
  selected: number
  onChange: Function
  menuPlacement?: 'top' | 'bottom' | 'auto'
}

const SelectWithSearch = ({ placeholder, selected, onChange, options, menuPlacement }: Props) => {
  const selectedValue = useMemo(() => {
    return _.find(options, { value: selected })
  }, [options, selected])

  return (
    <div>
      <Select
        //@ts-ignore
        options={options}
        value={selectedValue}
        classNamePrefix={'customSelect'}
        placeholder={placeholder || ''}
        isClearable
        isSearchable
        escapeClearsValue
        backspaceRemovesValue
        menuPlacement={menuPlacement || 'auto'}
        //@ts-ignore
        onChange={(val: { label: string; value: number }) => {
          if (val && val.value) {
            onChange(val.value)
          } else {
            onChange(null)
          }
        }}
      />
    </div>
  )
}

export default React.memo(SelectWithSearch)
