import { HeaderFooterContext } from 'application/app/HeaderFooter'
import { PageLayoutContext } from 'application/presentation/common/PageLayout/PageLayoutContext'
import React, { useContext, useEffect, useMemo } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from 'application/domain/store/store'

import s from './AuthPage.module.scss'
import RegForm from './components/RegForm'
import AuthForm from './components/AuthForm'
import PassResetForm from './components/PassResetForm'

const AuthPage = () => {
  const { user } = useSelector(({ userReducer }: RootState) => ({ user: userReducer.user }))
  const { pathname } = useLocation()
  const params = useParams()
  const headerContext = useContext(HeaderFooterContext)
  const pageContext = useContext(PageLayoutContext)
  const navigate = useNavigate()

  const isRegistration = useMemo(() => pathname.indexOf('registration') !== -1, [pathname])
  const isPasswordReset = useMemo(() => !!(params && params.token), [params])
  const showReg = true

  useEffect(() => {
    headerContext?.showHeader(false)
    pageContext?.showMenu(false)
    return () => {
      headerContext?.showHeader(true)
      pageContext?.showMenu(true)
    }
  }, [headerContext, pageContext])

  return (
    <div className={s.page_container}>
      <div className={s.header}>
        <img alt="logo" src="/don.png" />
        {/* {showReg && (
          <div className={s.right}>
            <div className={s.notice}>
              {isRegistration ? 'Уже есть аккаунт?' : 'Еще нет аккаунта?'}
            </div>
            <Button
              text={isRegistration ? 'Войти' : 'Зарегистрироваться'}
              color="orange"
              containerClassName={s.button}
              isLink
              replace
              to={isRegistration ? '/login' : '/registration'}
            />
          </div>
        )} */}
      </div>

      {isPasswordReset ? <PassResetForm /> : isRegistration ? <RegForm /> : <AuthForm />}
    </div>
  )
}

export default React.memo(AuthPage)
