import React from 'react'
import { ReactComponent as ArrowIcon } from 'images/icons/arrow.svg'
import { useNavigate } from 'react-router'

import InfoNotification from '../InfoNotification'

import s from './PageTitle.module.scss'

type Props = {
  title: string
  info?: string | React.ReactNode
  back?: boolean
  rightContent?: string | React.ReactNode
  rightClassName?: string
  backFunc?: Function
}

const PageTitle = ({ title, back, info, rightContent, rightClassName, backFunc }: Props) => {
  const nav = useNavigate()
  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '2rem' }}>
        {back && <ArrowIcon className={s.back} onClick={() => (backFunc ? backFunc() : nav(-1))} />}
        <div className={s.title}>{title}</div>
        {rightContent ? (
          <div className={`${s.right} ${rightClassName || ''}`}>{rightContent}</div>
        ) : (
          <></>
        )}
      </div>
      {info ? <InfoNotification text={info} /> : <></>}
    </div>
  )
}

export default React.memo(PageTitle)
