import _ from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import AddFilter from './components/AddFilter'
import SubmittedFilter from './components/SubmittedFilter'
import s from './Filters.module.scss'
import { checkIfHasValue } from './utils'

export type FilterItemType = {
  id: number
  name: string
  key: string
  type: 'selector' | 'input' | 'date_interval'
  hidden?: boolean
  value?: any
  index?: number
  strict_mode?: boolean
  data: Array<{
    id: number
    name: string
    type?: 'text' | 'number' | 'selector' | 'file' | 'password' | 'datetime' | 'time' | 'date'
    value: any
    index?: number
  }>
}

export type FilterDataType = Array<FilterItemType>

type Props = {
  data: FilterDataType
  onChange: (newData: FilterDataType) => void
}

const addIndexes = (data: FilterDataType) => {
  return _.map(data, (item, index) => ({
    ...item,
    index,
    data: _.map(item.data, (d, index2) => ({ ...d, index: index2 })),
  }))
}

const Filters = ({ data, onChange }: Props) => {
  const [filtersData, setFiltersData] = useState<FilterDataType>(addIndexes(data))

  useEffect(() => {
    setFiltersData(addIndexes(data))
  }, [data])

  const onFilterItemChange = useCallback(
    (value: any, filterIndex: number, filterItemIndex: number) => {
      let temp = _.cloneDeep(filtersData)
      temp = _.set(temp, `[${filterIndex}].data[${filterItemIndex}].value`, value)
      switch (temp[filterIndex].type) {
        case 'date_interval':
          temp[filterIndex].value = [
            filterItemIndex === 0 ? value : temp[filterIndex].value[0],
            filterItemIndex === 1 ? value : temp[filterIndex].value[1],
          ]
          break
        case 'selector':
          temp[filterIndex].value = value
          break
        case 'input':
          temp[filterIndex].value = value
          break
        default:
          break
      }
      setFiltersData(temp)
    },
    [filtersData],
  )

  const onFilterItemSubmit = useCallback(
    (filterIndex: number) => {
      let temp = _.cloneDeep(data)
      temp[filterIndex] = filtersData[filterIndex]
      if (temp[filterIndex].type === 'date_interval') {
        temp[filterIndex].value = [
          temp[filterIndex].value[0] ? temp[filterIndex].value[0] : temp[filterIndex].data[0].value,
          temp[filterIndex].value[1] ? temp[filterIndex].value[1] : temp[filterIndex].data[1].value,
        ]
      }
      onChange(temp)
    },
    [data, onChange, filtersData],
  )

  const onRemoveItemClick = useCallback(
    (id: number) => {
      let temp = _.cloneDeep(data)
      const index = _.findIndex(temp, { id: id })
      switch (temp[index].type) {
        case 'selector':
          temp[index].value = -1
          break
        case 'date_interval':
          temp[index].value = []
          break
        case 'input':
          temp[index].value = ''
          break
        default:
          break
      }
      setFiltersData(temp)
      onChange(temp)
    },
    [data, onChange],
  )

  let submittedFilters = useMemo(() => {
    return _.filter(data, (item) => checkIfHasValue(item))
  }, [data])

  let unSubmittedFilters = useMemo(() => {
    if (data) {
      return _.map(filtersData, (item, index) => {
        return {
          ...item,
          hidden: checkIfHasValue(data[index]),
        }
      })
    }
    return []
  }, [data, filtersData])

  return (
    <div className={s.container}>
      {submittedFilters && submittedFilters.length ? (
        submittedFilters.map((item) => {
          return (
            <SubmittedFilter
              key={`submitted_filter_${item.id}`}
              data={item}
              filterData={filtersData[item.index || 0]}
              onRemoveItemClick={() => onRemoveItemClick(item.id)}
              onFilterItemChange={onFilterItemChange}
              onFilterItemSubmit={onFilterItemSubmit}
            />
          )
        })
      ) : (
        <></>
      )}

      <AddFilter
        data={unSubmittedFilters}
        onFilterItemChange={onFilterItemChange}
        onFilterItemSubmit={onFilterItemSubmit}
      />
    </div>
  )
}

export default React.memo(Filters)
