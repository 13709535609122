import React, { useCallback, useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { ProductType, VarietyType } from 'types/catalogType'
import iconUrl from 'images/icons/arrow_prev.svg'
import { ReactComponent as CloseSvg } from 'images/icons/white_close_icon.svg'
import { useNavigate } from 'react-router-dom'
import ToastNotify from 'application/app/ToastNotify'
import { editProduct, addProduct } from 'application/domain/store/reducers/basketSlice'

import { Button } from '../../uiComponents'

import s from './ProductMW.module.scss'
import { getEditAdditionalsData } from './BasketActions'
import Prod from './components/Prod'
import Additional from './components/Additional'
import ProductItem from './components/ProductItem'
import SwitchItem from './components/SwitchItem'

const disconts = [
  {
    id: 1,
    value: 0,
    name: 'Без скидки',
  },
  {
    id: 2,
    value: 10,
    name: '10%',
  },
  {
    id: 3,
    value: 15,
    name: '15%',
  },
  {
    id: 4,
    value: 20,
    name: '20%',
  },
  {
    id: 5,
    value: 30,
    name: '30%',
  },
  {
    id: 6,
    value: 50,
    name: '50%',
  },
  {
    id: 7,
    value: 99,
    name: 'Переделан',
  },
]

type ProductMwContentProps = {
  opened: boolean
  product: ProductType
  basketData: any
  isEdit?: boolean
}

const ProductMwContent = ({ opened, product, basketData, isEdit }: ProductMwContentProps) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [selectedDiscont, setSelectedDiscont] = useState<number>(1)
  const [additionals, setAdditionals] = useState<any>()
  const [selectedVolume, setSelectedVolume] = useState<number>(1)

  const [activeDiscont, setActiveDiscont] = useState(1)
  const [activeVolumeSelector, setActiveVolumeSelector] = useState(1)

  const [activeTemp, setActiveTemp] = useState(false)

  useEffect(() => {
    if (isEdit && product) {
      const disc = _.find(disconts, {
        value: product.discont,
      })
      // console.log(product)
      setSelectedDiscont(disc ? disc.id : 1)
      setActiveDiscont(disc ? disc.id : 1)
      setActiveTemp(product.temperature === 'hot' ? true : false)
    } else {
      setSelectedDiscont(1)
    }
  }, [isEdit, opened, product])

  const volumeSelector = useMemo(() => {
    if (product && product.varieties) {
      return _.sortBy(
        product.varieties.map((item: any) => ({
          ...item,
          id: item.variety_id,
          value: `${item.cost}`,
        })),
        'cost',
      )
    }
    return undefined
  }, [product])

  useEffect(() => {
    if (volumeSelector) {
      if (isEdit && product) {
        const varietyVol = _.find(product.varieties, { selected: true })
        if (varietyVol) {
          setSelectedVolume(varietyVol.id)
          setActiveVolumeSelector(varietyVol.id)
        }
      } else {
        setSelectedVolume(volumeSelector[0].id)
        setActiveVolumeSelector(volumeSelector[0].id)
      }
    }
  }, [isEdit, product, volumeSelector])
  const additionalsData = useMemo(() => {
    if (product) {
      if (isEdit) {
        return getEditAdditionalsData(product)
      } else {
        return product.options.map((item: any) => ({
          ...item,
          selected: [false],
          items: item.items.map((v: VarietyType) => ({ ...v, selected: false, count: 0 })),
        }))
      }
    }
  }, [isEdit, product])

  useEffect(() => {
    if (additionalsData) {
      setAdditionals(additionalsData)
    }
  }, [additionalsData])

  const selectAdditional = useCallback(
    (item: any, addId: number, count: number) => {
      if (additionals) {
        const temp = _.cloneDeep(additionals)
        temp.map((a: any) => {
          if (parseInt(a.id, 10) === parseInt(item.id, 10)) {
            a.selected[0] = true
            a.items.map((o: any) => {
              if (o.id === addId) {
                a.selected.push(addId)
                a.selected = _.uniq(a.selected)
                o.selected = true
                o.count = count
              }
              if (addId === 0) {
                a.selected.length = 1
                a.selected[0] = false
                o.selected = false
                o.count = 0
              }
            })
          }
        })

        setAdditionals(temp)
      }
    },
    [additionals],
  )

  const clearState = useCallback(
    (onlyOpt?: boolean) => {
      setActiveDiscont(1)
      if (volumeSelector && !onlyOpt) {
        setActiveVolumeSelector(volumeSelector[0].id)
        setSelectedVolume(volumeSelector[0].id)
      }
      setActiveTemp(false)
      let tempAdditional = _.cloneDeep(additionals)
      tempAdditional = tempAdditional.map((item: any) => {
        item.selected[0] = false
        item.selected.length = 1
        if (item.selected) {
          item.selected[0] = false
          item.items.map((opt: any) => {
            opt.selected = false
            if (opt.selected) {
              opt.selected = false
              opt.count = 0
            }
            return opt
          })
        }
        return item
      })
      setAdditionals(tempAdditional)
    },
    [additionals, volumeSelector],
  )

  const addProductHandler = useCallback(
    (dat) => {
      const prod = _.cloneDeep(dat)
      if (prod.isDrink) {
        prod.temperature = !activeTemp ? 'warm' : 'hot'
      }
      const discont = _.find(disconts, { id: selectedDiscont })
      prod.discont = discont ? discont.value : 0
      prod.options = []
      if (prod.options) {
        const cloneOpt = _.cloneDeep(additionals)
        cloneOpt.map((addition: any) => {
          addition.selected = addition.selected[0]
        })
        prod.options = cloneOpt
        if (selectedVolume) {
          prod.varieties.map((item: any) => {
            item.selected = false
            if (item.variety_id === selectedVolume) {
              item.selected = true
            }
            return item
          })
        } else {
          prod.varieties[0].selected = true
        }

        if (isEdit) {
          editProduct(prod)(dispatch)
        } else {
          addProduct(prod)(dispatch)
          clearState()
        }

        ToastNotify(isEdit ? 'Отредактировано' : 'Добавлено в корзину')
        if (isEdit) {
          navigate(-1)
        }
      }
    },
    [
      selectedDiscont,
      activeTemp,
      additionals,
      selectedVolume,
      isEdit,
      dispatch,
      clearState,
      navigate,
    ],
  )

  const sumPrice = useMemo(() => {
    let total = 0
    if (additionals && volumeSelector) {
      additionals.forEach((item: any) => {
        if (item.selected) {
          item.items.forEach((opt: any) => {
            if (opt.selected) {
              total = total + opt.cost * opt.count
            }
          })
        }
      })

      volumeSelector.forEach((item: any) => {
        if (item.id === selectedVolume) {
          total += item.cost
        }
      })
    }

    return total
  }, [additionals, selectedVolume, volumeSelector])

  const editBtnClickHandler = useCallback(
    () => navigate(`/kassa_panel?product_id=${product.id}#edit_availability`),
    [navigate, product],
  )

  return (
    <>
      <div className={s.inner_container}>
        <div className={s.sticky}>
          <div className={s.custom_close_btn} onClick={() => navigate(-1)}>
            <CloseSvg />
          </div>
          <div className={s.title}>{product.product_name}</div>
          <div className={s.edit_title} onClick={editBtnClickHandler}>
            (Ред.)
          </div>
          <div className={s.right}>
            <div className={s.sum}>
              <div className={s.price}>{sumPrice} руб</div>
              <div className={s.disc}>без учета скидки</div>
            </div>
            <Button
              text={isEdit ? 'СОХРАНИТЬ' : 'ДОБАВИТЬ'}
              onClick={() => addProductHandler(product)}
              color="orange"
              className={s.orange_btn}
              containerClassName={s.orange_btn_container}
              iconUrl={iconUrl}
            />
          </div>
        </div>
        <div className={s.subtitle}>Объем напитка</div>
        <div className={s.adds_container}>
          {volumeSelector?.map((item: any, i: number) => {
            return (
              <ProductItem
                key={item.id}
                color="orange"
                title={item.name}
                price={item.value}
                onChange={() => {
                  setSelectedVolume(item.id)
                  clearState(true)
                }}
                id={item.id}
                active={activeVolumeSelector}
                setActive={setActiveVolumeSelector}
                availability_status={item.availability_status}
              />
            )
          })}
        </div>

        {additionals ? (
          <Prod
            selectAdditional={selectAdditional}
            selectedVolume={selectedVolume}
            additionals={additionals}
          />
        ) : (
          <></>
        )}

        <div className={s.subtitle}>Скидка (Сотрудник)</div>
        <div className={s.adds_container}>
          {disconts?.map((item: any, i: number) => {
            return (
              <ProductItem
                key={item.id}
                color="green"
                title={item.name}
                onChange={() => {
                  setSelectedDiscont(item.id)
                }}
                id={item.id}
                active={activeDiscont}
                setActive={setActiveDiscont}
              />
            )
          })}
        </div>

        {additionals ? (
          <div>
            <div className={s.subtitle}>Дополнительно</div>
            <div className={`${s.adds_container} ${s.switch_container}`}>
              <Additional selectAdditional={selectAdditional} additionals={additionals} />
              {product.isDrink ? (
                <SwitchItem
                  title={'Температура'}
                  price={0}
                  temp
                  data={null}
                  checked={activeTemp}
                  setChecked={setActiveTemp}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  )
}

export default React.memo(ProductMwContent)
