import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import InputMask from 'react-input-mask'
import { BonusCardType, ClientType } from 'types/UserType'
import apiClient from 'application/data/apiClient'
import { activateBonusCard, checkBonusCard } from 'application/domain/useCase/client/client'
import { useSelector } from 'react-redux'
import { RootState } from 'application/domain/store/store'
import { isBarista } from 'application/presentation/UserController'
import {
  addUserInSession,
  deleteUserInSession,
  getTeam,
  useSessionInfo,
} from 'application/domain/useCase/barista/barista'
import _ from 'lodash'
import queryClient from 'application/data/apiClient/queryClient'

import MWContainer from '../MWContainer'
import { useTheme } from '../../Hooks/useTheme'
import { Button, Checkbox } from '../../uiComponents'
import Loader from '../../Loader'
import { UniversalInputComponent } from '../../InputComponents'

import s from './AddBaristaOrAdeptMW.module.scss'

const AddBaristaOrAdeptMW = () => {
  // const { isLightTheme } = useTheme()
  const isLightTheme = true
  const navigate = useNavigate()
  const { hash, search } = useLocation()

  const { user, token, appMode } = useSelector(({ userReducer, navReducer }: RootState) => ({
    user: userReducer.user,
    token: userReducer.token,
    appMode: userReducer.appMode,
  }))

  const isBookingMode = useMemo(() => {
    return appMode === 'booking'
  }, [appMode])

  const userIsBarista = useMemo(() => isBarista(user), [user])

  const opened = useMemo(() => {
    return hash.indexOf('addUserInSession') !== -1 && userIsBarista
  }, [hash, userIsBarista])

  const { data: sessionData, isLoading: sessionIsLoading } = useSessionInfo(
    token && opened ? token : '',
  )

  const tableParams = useMemo(() => {
    const params = new URLSearchParams(search)
    const id = params.get('area_table_id')
    const num = params.get('table_num')
    const extraSession = params.get('extra_session')
    return {
      id: id ? parseInt(id, 10) : 0,
      num: num ? parseInt(num, 10) : 0,
      extra_session: extraSession ? parseInt(extraSession, 10) : 0,
    }
  }, [search])

  const extraSessionData = useMemo(() => {
    if (sessionData && sessionData.extra_sessions && tableParams.extra_session) {
      return _.find(sessionData.extra_sessions, (item) => item.id === tableParams.extra_session)
    }
    return null
  }, [sessionData, tableParams])

  const [data, setData] = useState<any>([])

  const [isBaristaCheck, setIsBaristaCheck] = useState<boolean>(true)
  const [isAdeptCheck, setIsAdeptCheck] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [workerId, setWorkerId] = useState<number>(0)
  const [btnLoading, setBtnLoading] = useState<boolean>(false)

  const deleteExtraSessionHandler = useCallback(
    (id: number) => {
      deleteUserInSession({
        onSuccess: (res: any) => {
          queryClient.invalidateQueries('sessionInfo').then(() => navigate(-1))
        },
        onError: (e: any) => {
          console.log('e', e)
        },
        extraSessionId: id,
      })
    },
    [navigate],
  )

  const getTeamHandler = useCallback(() => {
    setIsLoading(true)
    getTeam({
      onError: () => {
        setData([])
        setIsLoading(false)
      },
      onSuccess: (res: any) => {
        setData(res)
        setWorkerId(res[0].id)
        setIsLoading(false)
      },
      isBarista: isBaristaCheck,
    })
  }, [isBaristaCheck])

  const usersOptions = useMemo(() => {
    if (data?.length) {
      const options = _.map(data, (item: any) => ({
        value: item.id,
        label: `${item.name} (${item.email})`,
      }))
      if (sessionData && sessionData.extra_sessions) {
        const alreadyInWorkIds = _.map(sessionData.extra_sessions, (item) => item.user_id)
        if (alreadyInWorkIds.length) {
          return _.filter(options, (item) => alreadyInWorkIds.indexOf(item.value) === -1)
        }
      }
      return options
    }
    return []
  }, [data, sessionData])

  useEffect(() => {
    if (opened) {
      getTeamHandler()
    }
  }, [getTeamHandler, opened])

  const toggleHandler = useCallback((barista?: boolean) => {
    if (barista) {
      setIsBaristaCheck(true)
      setIsAdeptCheck(false)
    } else {
      setIsAdeptCheck(true)
      setIsBaristaCheck(false)
    }
  }, [])

  const addUserHandler = useCallback(() => {
    setBtnLoading(true)
    addUserInSession({
      onError: (e: any) => {
        console.log('e', e)
        setBtnLoading(false)
      },
      onSuccess: (res: any) => {
        queryClient.invalidateQueries('sessionInfo').then(() => {
          if (tableParams.id) {
            navigate(-1)
          } else {
            navigate('/kassa_work')
          }
        })
        setBtnLoading(false)
      },
      isBarista: isBaristaCheck,
      userId: workerId,
      tableId: tableParams.id,
    })
  }, [isBaristaCheck, navigate, workerId, tableParams])

  return (
    <MWContainer
      isOpen={opened}
      onRequestClose={() => {
        if (hash.indexOf('addUserInSession') !== -1 && userIsBarista) {
          navigate(-1)
        }
      }}
      className={isLightTheme ? s.light : ''}
      btnClassName={isLightTheme ? s.light_btn : ''}
    >
      <div className={s.title}>
        {tableParams.num ? `В боксе ${tableParams.num}` : 'Добавление сотрудника в смену'}
      </div>
      <div className={s.inner_container}>
        {!tableParams.id && !isBookingMode && (
          <div className={s.input_container}>
            <div className={s.check}>
              <div className={s.check_title} onClick={() => toggleHandler(true)}>
                Бариста
              </div>
              <Checkbox value={isBaristaCheck} onChange={() => toggleHandler(true)} />
            </div>
            <div className={s.check}>
              <div className={s.check_title} onClick={() => toggleHandler()}>
                Адепт
              </div>
              <Checkbox value={isAdeptCheck} onChange={() => toggleHandler()} />
            </div>
          </div>
        )}

        {tableParams.extra_session ? (
          <>
            {sessionIsLoading ? (
              <Loader />
            ) : extraSessionData ? (
              <div className={s.extra_user} key={extraSessionData.id}>
                <div className={s.extra_user_name}>
                  {extraSessionData.name} {extraSessionData.start}
                </div>
                <Button
                  text="Удалить"
                  color="red"
                  onClick={() => deleteExtraSessionHandler(extraSessionData.id)}
                  containerClassName={s.extra_btn_container}
                />
              </div>
            ) : (
              <>Сессия не найдена</>
            )}
          </>
        ) : (
          <div className={s.selector_container}>
            {isLoading ? (
              <Loader size={16} containerClassName={s.loader} />
            ) : data ? (
              <UniversalInputComponent
                type="selector"
                title={'Выберите сотрудника'}
                value={workerId}
                options={usersOptions}
                onChange={(e: any) => setWorkerId(e)}
              />
            ) : (
              <div>Не удалось загрузить данные</div>
            )}
            <Button
              text={'Добавить'}
              onClick={addUserHandler}
              color={'orange'}
              isLoading={btnLoading}
              containerClassName={s.btn_container}
            />
          </div>
        )}
      </div>
    </MWContainer>
  )
}

export default React.memo(AddBaristaOrAdeptMW)
