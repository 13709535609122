import apiClient from '../apiClient/apiClient'

export const passResetEmailRequest = async (email: string) => {
  const { data } = await apiClient.post('/api/user/forgotPassword?panel=true', { email })
  return data
}

export const loginRequest = async (authData: { phone: string; password: string }) => {
  const { data } = await apiClient.post('/api/login', authData)
  return data
}

export const registrationRequest = async (authData: {
  name: string
  password: string
  password_confirmation: string
  email: string
}) => {
  const { data } = await apiClient.post('/api/user/register?panel=true', authData)
  return data
}

export const saveNewPasswordRequest = async (sendData: {
  token: string
  email: string
  password: string
  password_confirmation: string
}) => {
  const { data } = await apiClient.post('/api/user/resetPassword?panel=true', sendData)
  return data
}
