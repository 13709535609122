import { useTheme } from 'application/presentation/common/Hooks/useTheme'
import _ from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import s from './OptionItem.module.scss'

type OptionItemPros = {
  selectAdditional: Function
  item: any
  additionals: any
  count: number
  option: any
  color: 'green' | 'orange' | 'material'
  className?: string
  title: string | number
  price?: number
}

const OptionItem = ({
  selectAdditional,
  item,
  option,
  count,
  additionals,
  color,
  className,
  title,
  price,
}: OptionItemPros) => {
  const isActive = useMemo(
    () =>
      item && item.selected && Array.isArray(item.selected)
        ? item.selected?.includes(option.id)
        : false,
    [item, option.id],
  )
  const [counter, setCounter] = useState(count ? count : 1)

  const { isLightTheme } = useTheme()

  // useEffect(() => {
  //   if (isActive && count) {
  //     setCounter(count)
  //   }
  //   return () => {
  //     setCounter(1)
  //   }
  // }, [count, isActive])

  const incrementCountHandler = useCallback((e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setCounter((v) => v + 1)
  }, [])

  const decrementCountHandler = useCallback((e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setCounter((v) => Math.max(v - 1, 1))
  }, [])

  const changeCounterHandler = useCallback(() => {
    if (selectAdditional && additionals) {
      selectAdditional(_.find(additionals, { id: item.id }), option.id, counter)
    }
  }, [additionals, counter, item.id, option.id, selectAdditional])

  // const isAvailable = useMemo(() => option.availability_status === 'in_stock', [option])
  const isAvailable = useMemo(() => true, [])

  useEffect(() => {
    if (isActive && isAvailable) {
      changeCounterHandler()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter, isActive, isAvailable])

  return (
    <div
      onClick={() => selectAdditional(_.find(additionals, { id: item.id }), option.id, 1)}
      className={`${s.item} ${s.item_center} ${
        isActive ? `${s[`active_${color}`]} ${isLightTheme ? s.light_active : ''}` : ''
      } ${className || ''} ${isActive && price ? s.item_option : ''} ${
        isLightTheme ? s.light : ''
      } `}
    >
      {isActive && price ? (
        <div className={s.float_handles}>
          <div className={s.plus} onClick={incrementCountHandler}>
            <div className={s.icon}></div>
          </div>
          <div className={s.minus} onClick={decrementCountHandler}>
            <div className={s.icon}></div>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className={`${s.title}`}>
        {title} {isActive && price ? `(${counter}шт) ` : ''}
      </div>
      {price && price !== 0 ? <div className={s.price}>{price} руб</div> : <></>}

      {isAvailable && <div className={s.in_stock_badge}></div>}
    </div>
  )
}

export default React.memo(OptionItem)
