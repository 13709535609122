import React, { useCallback } from 'react'
import { AuthUserType } from 'types/UserType'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import {
  isAdmin,
  isConditer,
  isSuperAdmin,
  isSupplier,
} from 'application/presentation/UserController'
import { RootState } from 'application/domain/store/store'
import { useTranslation } from 'react-i18next'
import { setCollapseNavMenu } from 'application/domain/store/reducers/navSlice'
import { NavLink } from 'react-router-dom'

import s from './NavigationMenu.module.scss'
import BaristaMenu from './roleMenu/BaristaMenu'
import SupplierMenu from './roleMenu/SupplierMenu'
import AdminMenu from './roleMenu/AdminMenu'
import ConditerMenu from './roleMenu/ConditerMenu'
import SuperAdminMenu from './roleMenu/SuperAdminMenu'

const NavByRole = React.memo(({ user }: { user: AuthUserType }) => {
  if (isSuperAdmin(user)) {
    return <SuperAdminMenu />
  }
  if (isAdmin(user)) {
    return <AdminMenu />
  }
  if (isSupplier(user)) {
    return <SupplierMenu />
  }
  if (isConditer(user)) {
    return <ConditerMenu />
  }
  return <BaristaMenu />
})

const NavigationMenu = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { user, collapseNavMenu } = useSelector(({ userReducer, navReducer }: RootState) => ({
    user: userReducer.user,
    collapseNavMenu: navReducer.collapseNavMenu,
  }))

  return (
    <div className={`${s.content} ${collapseNavMenu ? s.collapsed : ''}`}>
      <NavByRole user={user} />
      <div>
        <div className={`${s.nav_last_info} ${collapseNavMenu ? s.collape_info : ''}`}>
          {t('nav.info')}
        </div>
        <NavLink
          className={`${s.nav_last_info} ${collapseNavMenu ? s.collape_info : ''}`}
          to="#iframe"
        >
          Что нового?
        </NavLink>
      </div>
    </div>
  )
}

export default React.memo(NavigationMenu)
