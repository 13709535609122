import { showNotification } from 'application/app/ToastNotify/ToastNotify'
import axios from 'axios'

const apiClient = axios.create({
  //@ts-ignore
  baseURL: window.ENV && window.ENV.url ? window.ENV.url : 'https://isdon-s4.vsezbs.lol/',
  // window.location.origin === 'http://localhost:3000'
  //   ? 'https://isdon-s2.vsezbs.space/'
  // baseURL: 'https://isdon-s0.vsezbs.space/',
  headers: {
    'Content-Type': 'application/json',
  },
})

// Add a request interceptor
apiClient.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config
  },
  function (error) {
    // Do something with request error
    // showNotification({
    //   title: 'Произошла ошибка',
    //   error: error,
    //   type: 'error',
    // })
    return Promise.reject(error)
  },
)

// Add a response interceptor
apiClient.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    // showNotification({
    //   title: 'Произошла ошибка',
    //   error: error,
    //   type: 'error',
    // })
    return Promise.reject(error)
  },
)

export default apiClient
