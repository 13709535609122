import apiClient from '../apiClient'

export const fetchStatistics = async (outlet_id?: number) => {
  const { data } = await apiClient.get(
    `/api/statistics${outlet_id ? `?outlet_id=${outlet_id}` : ''}`,
  )
  return data
}

export const fetchOutletsAverageStats = async () => {
  const { data } = await apiClient.get(
    'https://stat.isdon.ru/api/get-average?access_cred=eyJSb2xlIjoiQWRtaW4iLCJJc3N1ZXIiOiJJc3N1ZXIiLCJVc2VybmFtZ',
  )
  return data
}
