import {
  fetchClients,
  deleteClientRequest,
  editClientRequest,
  fetchStepOneEncashment,
  fetchStepTwoEncashment,
  fetchStatBarista,
  movingAtomRequest,
  checkCallPersonalRequest,
  fetchTeamRequest,
  addUserInSessionRequest,
  getSessionInfo,
  deleteUserInSessionRequest,
  createDonSessionLogRequest,
  getShowMyCurrentSalaryRequest,
  sendSalariesInfoForBaristaRequest,
} from 'application/data/api/barista'
import { MovingAtom } from 'application/domain/types/barista'
import { CallbackType } from 'application/domain/types/callback'
import { useQuery } from 'react-query'
import moment from 'moment'

export const useClients = () => {
  const query = useQuery(['clients'], fetchClients, {
    retry: false,
    refetchOnWindowFocus: false,
  })
  return query
}

export const useSessionInfo = (token: string) => {
  const query = useQuery(['sessionInfo'], getSessionInfo, {
    enabled: token ? true : false,
    retry: false,
  })

  return { ...query, isLoading: token ? query.isLoading : false }
}

export const deleteClient = async ({ onSuccess, onError, id }: { id: number } & CallbackType) => {
  try {
    const data = await deleteClientRequest(id)
    return onSuccess(data)
  } catch (e) {
    return onError(e)
  }
}

export const editClient = async ({
  onSuccess,
  onError,
  editUserId,
  name,
  phone,
  birthday,
  email,
  surname,
}: {
  editUserId: number
  name: string
  phone: string
  birthday: string
  email: string
  surname: string
} & CallbackType) => {
  try {
    const data = await editClientRequest(editUserId, name, phone, birthday, email, surname)
    return onSuccess(data)
  } catch (e) {
    return onError(e)
  }
}

export const getEncashmentStepOne = async ({
  onSuccess,
  onError,
  sendData,
  stepData,
}: {
  sendData: any
  stepData: any
} & CallbackType) => {
  try {
    const data = await fetchStepOneEncashment(sendData, stepData)
    return onSuccess(data)
  } catch (e) {
    return onError(e)
  }
}

export const movingAtom = async ({
  onSuccess,
  onError,
  sendData,
}: {
  sendData: MovingAtom
} & CallbackType) => {
  try {
    const data = await movingAtomRequest(sendData)
    return onSuccess(data)
  } catch (e) {
    return onError(e)
  }
}

export const getEncashmentStepTwo = async ({
  onSuccess,
  onError,
  code,
  userId,
}: {
  code: string
  userId?: number
} & CallbackType) => {
  try {
    const data = await fetchStepTwoEncashment(code, userId)
    return onSuccess(data)
  } catch (e) {
    return onError(e)
  }
}

export const useStatBarista = (isBarista: boolean, date?: string, config?: any) => {
  const selectedDate = moment().isSame(moment(date), 'day') ? undefined : date
  const query = useQuery(['allSells', selectedDate || ''], () => fetchStatBarista(selectedDate), {
    enabled: isBarista ? true : false,
    retry: false,
    refetchOnWindowFocus: false,
    ...config,
  })
  return query
}

export const sendCheckCallPersonal = checkCallPersonalRequest

export const getTeam = async ({
  onSuccess,
  onError,
  isBarista,
}: {
  isBarista: boolean
} & CallbackType) => {
  try {
    const data = await fetchTeamRequest(isBarista)
    return onSuccess(data)
  } catch (e) {
    return onError(e)
  }
}

export const addUserInSession = async ({
  onSuccess,
  onError,
  isBarista,
  userId,
  tableId,
}: {
  isBarista: boolean
  userId: number
  tableId?: number
} & CallbackType) => {
  try {
    const data = await addUserInSessionRequest(isBarista, userId, tableId)
    return onSuccess(data)
  } catch (e) {
    return onError(e)
  }
}

export const deleteUserInSession = async ({
  onSuccess,
  onError,
  extraSessionId,
}: {
  extraSessionId: number
} & CallbackType) => {
  try {
    const data = await deleteUserInSessionRequest(extraSessionId)
    return onSuccess(data)
  } catch (e) {
    return onError(e)
  }
}

export const createDonSessionLog = createDonSessionLogRequest

export const useMyCurrentSalary = () => {
  const query = useQuery(['my_current_salary'], getShowMyCurrentSalaryRequest)
  return query
}

export const sendSalariesInfoForBarista = async ({
  onSuccess,
  onError,
  sendData,
}: {
  sendData: any
} & CallbackType) => {
  try {
    const data = await sendSalariesInfoForBaristaRequest(sendData)
    return onSuccess(data)
  } catch (e) {
    return onError(e)
  }
}
