import React, { useMemo } from 'react'
import cn from 'classnames'
import _isArray from 'lodash/isArray'
import _isObject from 'lodash/isObject'

import s from './ErrorMsg.module.scss'

type Props = {
  text: string | Array<any> | Object
  containerStyle?: any
  style?: any
}

const ErrorMsg = ({ text, containerStyle, style }: Props) => {
  const errorMsg = useMemo(() => {
    return typeof text === 'string'
      ? text
      : _isArray(text)
      ? text.toString()
      : _isObject(text)
      ? JSON.stringify(text)
      : 'Не удалось преобразовать ошибку в строку'
  }, [text])
  return (
    <div className={containerStyle || ''}>
      <div className={cn(s.msg, style || '')}>{errorMsg}</div>
    </div>
  )
}

export default React.memo(ErrorMsg)
