import React from 'react'
import { ReactComponent as CheckSvg } from 'images/icons/check_icon.svg'
import cn from 'classnames'

import s from './Checkbox.module.scss'

type Props = {
  value: boolean
  onChange: Function
  title?: string
  containerClassName?: string
  className?: string
  activeClassName?: string
  textClassName?: string
}

const Checkbox = ({
  value,
  title,
  onChange,
  containerClassName,
  className,
  activeClassName,
  textClassName,
}: Props) => {
  return (
    <label className={cn(s.container, containerClassName || '')} onClick={() => onChange(!value)}>
      <div
        className={cn(
          s.checkbox,
          className || '',
          value ? `${s.active} ${activeClassName || ''}` : '',
        )}
      >
        {value && <CheckSvg />}
      </div>
      {title ? <span className={textClassName || ''}>{title}</span> : <></>}
    </label>
  )
}

export default React.memo(Checkbox)
