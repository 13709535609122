import { useOutlets } from 'application/domain/useCase/outlet/outlet'
import Loader from 'application/presentation/common/Loader/Loader'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Outlet } from 'application/domain/entity/outlet/Outlet'
import _map from 'lodash/map'
import cn from 'classnames'
import { getTechnicalBreak } from 'application/domain/useCase/outlet/outletActions'
import convertRemToPixels from 'application/presentation/common/PureFunctions/remToPixels'
import moment from 'moment'
import { useOutletsAverageStatistics } from 'application/domain/useCase/statistics/getStatistics'
import _find from 'lodash/find'
import { secondsToMinutesAndSeconds } from 'application/utils/timeConverter'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { UniversalInputComponent } from 'application/presentation/common/InputComponents'
import { Button } from 'application/presentation/common/uiComponents'

import s from './OutletsAvailabilityListPage.module.scss'

const OutletsAvailabilityListPage = () => {
  const { search } = useLocation()
  const navigate = useNavigate()
  const [settingTime, setSettingTime] = useState<number>(1)
  const { data, isLoading } = useOutlets({
    refetchInterval: 600000,
  })
  const { data: averageStatsData, isLoading: averageStatsIsLoading } = useOutletsAverageStatistics({
    refetchInterval: 60000,
    retry: false,
  })

  const outlets = useMemo(() => {
    const settings = { id: 0, name: 'Настройки' }

    if (data && averageStatsData) {
      const stat =
        //@ts-ignore
        averageStatsData[Object.keys(averageStatsData)[Object.keys(averageStatsData).length - 1]]

      const outletsAverage = _map(data.outlets, (item) => ({
        ...item,
        averageStats: _find(stat, { outlet_id: item.id }),
      }))
      const withSettings = [settings, ...outletsAverage]
      return data ? withSettings : []
    }
    const withSettings = [settings, ...data.outlets]
    return data ? withSettings : []
  }, [averageStatsData, data])

  const elemData = useMemo(() => {
    const elemCount = outlets.length
    if (elemCount) {
      const { innerWidth, innerHeight } = window
      let width = innerWidth
      let height = innerHeight
      const square = (width * height) / elemCount // площадь одного элемента
      const sqrt = Math.floor(Math.sqrt(square)) // ширина стороны элемента
      const elemCountInRow = Math.floor(width / sqrt)
      const elemCountInColumn = Math.floor(height / sqrt)
      if (elemCountInRow * elemCountInColumn < elemCount) {
        let elemWidth = sqrt
        let inRow = elemCountInRow
        let inColumn = elemCountInColumn
        while (inRow * inColumn < elemCount) {
          elemWidth = elemWidth - 1
          inColumn = Math.floor(height / elemWidth)
          inRow = Math.floor(width / elemWidth)
        }
        return {
          width: elemWidth,
          elemCountInRow: inRow,
        }
      }

      return { width: sqrt, elemCountInRow }
    }
    return { width: 0, elemCountInRow: 0 }
  }, [outlets])

  const doneTime = useMemo(() => {
    const params = new URLSearchParams(search)
    const t = params.get('done_time')
    return t ? parseInt(t, 10) : 0
  }, [search])

  useEffect(() => {
    if (doneTime) {
      setSettingTime(doneTime)
    }
  }, [doneTime])

  const saveSettingTime = useCallback(() => {
    navigate(`?done_time=${settingTime}`)
  }, [navigate, settingTime])

  return isLoading || averageStatsIsLoading ? (
    <Loader />
  ) : (
    <div className={s.container}>
      {outlets.map((item: any, index: number) => {
        if (item.id == 0) {
          return (
            <div
              className={cn(s.item)}
              style={{
                width: elemData.width,
                paddingBottom: elemData.width,
              }}
              key={item.id}
            >
              <div className={s.name}>
                {item.name}

                <p className={s.paragraph}>Время обработки</p>
                <div className={s.time_container}>
                  <UniversalInputComponent
                    type={'number'}
                    placeholder="Время в минутах"
                    title=""
                    onChange={(val: number) => setSettingTime(val)}
                    value={settingTime}
                    containerClassName={s.input_container}
                  />
                  <Button
                    text="Сохранить"
                    color="red"
                    onClick={saveSettingTime}
                    containerClassName={s.btn_container}
                  />
                </div>
              </div>
            </div>
          )
        }
        const techBreak = getTechnicalBreak(item)
        const sessionIsFinished =
          item.don_session_finish &&
          item.don_session_finish !== '0000-00-00 00:00:00' &&
          moment().isAfter(moment(item.don_session_finish))
            ? true
            : false
        const time = item.averageStats ? secondsToMinutesAndSeconds(item.averageStats.time) : null
        const closed = techBreak || sessionIsFinished
        return (
          <NavLink
            className={cn(
              s.item,
              { [s.closed]: closed ? true : false },
              { [s.slow]: !closed && time && +time?.minutes > settingTime ? true : false },
            )}
            style={{
              width: elemData.width,
              paddingBottom: elemData.width,
            }}
            key={item.id}
            to={`/result/all_stats?outlet_id=${item.id}`}
          >
            <div className={s.name}>
              {item.name} {item.is_distance_order ? '(Д)' : ''}
              {techBreak ? (
                <p className={s.paragraph}>
                  Тех.перерыв
                  <br />
                  {techBreak}
                </p>
              ) : sessionIsFinished ? (
                <p className={s.paragraph}>
                  Закрыта с
                  <br />
                  {moment(item.don_session_finish).format('DD.MM.YY HH:mm')}
                </p>
              ) : (
                ''
              )}
              <p className={s.paragraph}>
                По заказам:
                <br />
                {item.averageStats ? (
                  <>
                    Кол-во: {item.averageStats.orders_count}
                    <br />
                    {time ? `Время: ${time.minutes}мин ${time.seconds}сек` : ''}
                  </>
                ) : (
                  'нет данных'
                )}
              </p>
            </div>
          </NavLink>
        )
      })}
    </div>
  )
}

export default React.memo(OutletsAvailabilityListPage)
