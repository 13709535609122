import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import InputMask from 'react-input-mask'
import { BonusCardType, ClientType } from 'types/UserType'
import apiClient from 'application/data/apiClient'
import { activateBonusCard, checkBonusCard } from 'application/domain/useCase/client/client'

import MWContainer from '../MWContainer'
import { useTheme } from '../../Hooks/useTheme'
import { Button } from '../../uiComponents'

import s from './BonusCardMW.module.scss'

const BonusCardMW = () => {
  const inputRef = useRef<any>(null)
  const cardRef = useRef<any>(null)
  const { isLightTheme } = useTheme()
  const navigate = useNavigate()
  const { hash } = useLocation()
  const opened = useMemo(() => {
    return hash.indexOf('bonus_card') !== -1
  }, [hash])
  const [cardNumber, setCardNumber] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [clientData, setClientData] = useState<ClientType | undefined>()
  const [cardData, setCardData] = useState<BonusCardType | undefined>()

  const [error, setError] = useState<string>('')

  useEffect(() => {
    setClientData(undefined)
    setCardData(undefined)
    setError('')
  }, [phone, cardNumber])

  const checkCard = useCallback(() => {
    setIsLoading(true)

    return checkBonusCard({
      phone: phone,
      code: cardNumber,
      onSuccess: (data: any) => {
        if (data.client) {
          setClientData(data.client)
        } else {
          setError('Клиент не найден')
        }
        if (data.card) {
          setCardData(data.card)
        } else {
          setError('Карта не найдена')
        }
        if (data.client && data.card) {
          if (data.card.user_id && data.client.id !== data.card.user_id) {
            setError('Карта принадлежит другому пользователю')
          }
        }
        setIsLoading(false)
      },
      onError: (err: any) => {
        setIsLoading(false)
      },
    })
  }, [cardNumber, phone])

  const activateCard = useCallback(() => {
    setIsLoading(true)

    return activateBonusCard({
      client: clientData,
      card: cardData,
      onSuccess: (data: any) => {
        setIsLoading(false)
        if (data) {
          if (data.status === 'OK') {
            navigate(-1)
          } else {
            setError(data.message)
          }
        } else {
          setError('Произошла ошибка при активации карты')
        }
      },
      onError: (err: any) => {
        setError('Произошла ошибка при активации карты')
        setIsLoading(false)
      },
    })
  }, [clientData, cardData, navigate])

  return (
    <MWContainer
      isOpen={opened}
      onRequestClose={() => {
        if (hash.indexOf('bonus_card') !== -1) {
          navigate(-1)
        }
      }}
      className={isLightTheme ? s.light : ''}
      btnClassName={isLightTheme ? s.light_btn : ''}
    >
      <div className={s.title}>Бонусная карта</div>
      <div className={s.inner_container}>
        <div className={s.input_block} onClick={() => cardRef.current.focus()}>
          <div className={s.input_title}>Введите номер карты</div>
          <input
            value={cardNumber}
            onChange={(e: any) => setCardNumber(e.target.value)}
            ref={cardRef}
          />
        </div>
        <div className={s.input_block} onClick={() => inputRef.current?.getInputDOMNode().focus()}>
          <div className={s.input_title}>Введите номер телефона</div>
          <InputMask
            mask="+7 (999) 999-99-99"
            alwaysShowMask
            // inputRef={inputRef}
            ref={inputRef}
            maskChar={'_'}
            inputMode="numeric"
            value={phone}
            onChange={(e: any) => setPhone(e.target.value)}
          />
        </div>
        {clientData ? (
          <div className={s.card_info}>
            <div className={s.info_item}>
              <div>Идентификатор клиента:</div>
              <div>{clientData.id}</div>
            </div>
            <div className={s.info_item}>
              <div>Имя клиента:</div>
              <div>{clientData.name}</div>
            </div>
          </div>
        ) : (
          <></>
        )}
        {cardData ? (
          <div className={s.card_info}>
            <div className={s.info_item}>
              <div>Статус карты:</div>
              <div>{cardData.status}</div>
            </div>
            <div className={s.info_item}>
              <div>Баланс:</div>
              <div>{cardData.nominal_cost} ₽</div>
            </div>
            <div className={s.info_item}>
              <div>Идентификатор владельца карты:</div>
              <div>{cardData.user_id}</div>
            </div>
          </div>
        ) : (
          <></>
        )}

        {error ? <div className={s.error}>{error}</div> : <></>}
        <Button
          text={clientData && cardData ? 'Активировать карту' : 'Проверить карту'}
          onClick={() => (cardData ? activateCard() : checkCard())}
          color={clientData && cardData ? 'green' : 'orange'}
          isLoading={isLoading}
          containerClassName={s.btn_container}
        />
      </div>
    </MWContainer>
  )
}

export default React.memo(BonusCardMW)
