import React, { useCallback, useMemo, useState } from 'react'
import { InputLabel } from 'application/presentation/common/InputComponents'
import { useLocation, useNavigate, useParams } from 'react-router'
import { Button, ErrorMsg } from 'application/presentation/common/uiComponents'
import { saveNewPassword } from 'application/domain/useCase/user/userActions'

import s from '../AuthPage.module.scss'

const PassResetForm = () => {
  const navigate = useNavigate()
  const { token } = useParams<{ token: string }>()
  const { search } = useLocation()
  const [password, setPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const email = useMemo(() => {
    const p = new URLSearchParams(search)
    return p.get('email') || ''
  }, [search])

  const reset = useCallback(() => {
    setError('')
    if (password.length < 4) {
      return setError('Длина пароля должна быть более 4-х символов')
    }
    if (password !== confirmPassword) {
      return setError('Пароли не совпадают')
    }
    setIsLoading(true)
    saveNewPassword({
      token: token || '',
      email,
      password,
      password_confirmation: confirmPassword,
    })
      .then(({ data }: any) => {
        setIsLoading(false)
        if (data.resetEmail.status === false) {
          setError(data.resetEmail.errors.password[0])
        } else {
          navigate('/login')
        }
      })
      .catch(() => {
        setIsLoading(false)
      })
  }, [confirmPassword, email, navigate, password, token])

  return (
    <div className={s.form}>
      <div className={s.title}>{'Восстановление пароля'}</div>
      <div className={s.fields}>
        <InputLabel title={'Электронная почта'} value={email} onChange={() => {}} />
        <InputLabel title={'Пароль'} value={password} onChange={setPassword} type="password" />
        <InputLabel
          title={'Повторите пароль'}
          value={confirmPassword}
          onChange={setConfirmPassword}
          type="password"
        />
        {error ? <ErrorMsg text={error} /> : <></>}
        <Button
          text="Поехали!"
          containerClassName={s.big_btn}
          color={'orange'}
          disabled={isLoading}
          onClick={reset}
          isLoading={isLoading}
        />
      </div>
    </div>
  )
}

export default React.memo(PassResetForm)
