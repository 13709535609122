import { EditAdminOutletData, EditOutletParamsData } from 'application/domain/types/requestTypes'

import apiClient from '../apiClient'

export const fetchOutlets = async () => {
  const { data } = await apiClient.get('/api/outlets')
  return data
}

export const fetchEditBreakOutlet = async (
  outlet_id: number,
  outletIsWorking: boolean,
  timeStart: string,
  timeEnd: string,
) => {
  const { data } = await apiClient.post('/api/outlets/edit_break', {
    outlet_id,
    break_time: outletIsWorking ? `${timeStart}-${timeEnd}` : '00:00-00:00',
  })
  return data
}

export const editOutletRequset = async (
  name: string,
  address: string,
  timeWork: string,
  width: string,
  height: string,
  typeName: string,
) => {
  const { data } = await apiClient.post('/api/outlets/edit', {
    name,
    address,
    time_work: timeWork,
    width,
    height,
    type: typeName,
  })

  return data
}

export const checkTechTimeRequest = async (outlet_id: number) => {
  const { data } = await apiClient.get(`/api/outlets/check/${outlet_id}`)

  return data
}

export const editTechTimeRequest = async (
  outlet_id: number,
  outletIsWorking: boolean,
  timeStart: string,
  timeEnd: string,
) => {
  const { data } = await apiClient.post('/api/outlets/edit_break', {
    outlet_id,
    break_time: outletIsWorking ? `${timeStart}-${timeEnd}` : '00:00-00:00',
  })

  return data
}

export const fetchAdminOutlets = async () => {
  const { data } = await apiClient.get('/api/admin/outlets?panel=true')
  return data
}

export const fetchAdminOutlet = async (id: number) => {
  const { data } = await apiClient.get(`/api/admin/outlets/${id}/edit?panel=true`)
  return data
}

export const addAdminOutletRequest = async (sendData: EditAdminOutletData) => {
  const { data } = await apiClient.post('/api/admin/outlets?panel=true&add_distance=true', sendData)
  return data
}

export const editAdminOutletRequest = async (sendData: EditAdminOutletData) => {
  const { data } = await apiClient.post(
    `/api/admin/products/${sendData.id}/save?panel=true&add_distance=true`,
    sendData,
  )
  return data
}

export const deleteAdminOutletRequst = async (id: number) => {
  const { data } = await apiClient.delete(`/api/admin/outlets/${id}?panel=true`)
  return data
}

export const fetchOutletProducts = async (id: number) => {
  const { data } = await apiClient.get(`/api/admin/outlets/${id}/products?panel=true`)
  return data
}

export const editOutletProductsRequest = async (outlet_id: number, products_exist: any) => {
  const { data } = await apiClient.post(
    `/api/admin/outlets/${outlet_id}/products/update?panel=true`,
    products_exist,
  )
  return data
}

export const editOutletParameterRequest = async (sendData: EditOutletParamsData) => {
  const { data } = await apiClient.post('/api/outlets/branding', {
    params: sendData,
  })
  return data
}
