import React, { useCallback, useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
// import { ReactComponent as StarOrangeSvg } from 'images/icons/star_orange.svg'
// import { ReactComponent as SettingsIcon } from 'images/icons/settings_icon.svg'
// import { ReactComponent as NotifyIcon } from 'images/icons/notify_icon.svg'
import { ReactComponent as MenuIcon } from 'images/icons/menu_icon.svg'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import { getUserRole, isSupplier } from 'application/presentation/UserController'
import { AuthUserType } from 'types/UserType'
import { IsPortraitOrientationContext } from 'application/app/isPortraitOrientation'
import {
  setShowNavMenu,
  setShowHeaderMenu,
  setCollapseNavMenu,
} from 'application/domain/store/reducers/navSlice'
import { RootState } from 'application/domain/store/store'
import { useTranslation } from 'react-i18next'

import { TechnicalTimeoutMW } from '../ModalWindows'

import s from './Header.module.scss'
import HeadUserBlock from './components/HeadUserBlock'
import SupplierHeader from './roleHeaders/SupplierHeader'
import BaristaHeader from './roleHeaders/BaristaHeader'

export const HeaderNavByRole = React.memo(({ user }: { user: AuthUserType }) => {
  if (isSupplier(user)) {
    return <SupplierHeader />
  }
  return <BaristaHeader />
})

const Header = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const location = useLocation()
  const isPortrait = useContext(IsPortraitOrientationContext)
  const { user, showNavMenu, showHeaderMenu, collapseNavMenu } = useSelector(
    ({ userReducer, navReducer }: RootState) => ({
      user: userReducer.user,
      showNavMenu: navReducer.showNavMenu,
      showHeaderMenu: navReducer.showHeaderMenu,
      collapseNavMenu: navReducer.collapseNavMenu,
    }),
  )

  useEffect(() => {
    setShowNavMenu(false)(dispatch)
  }, [location, dispatch])

  const onCollapseHandler = useCallback(() => setCollapseNavMenu()(dispatch), [dispatch])

  const basicBarista = user?.session?.state === 'active'

  return (
    <>
      {isPortrait ? (
        <div className={s.header}>
          {basicBarista ? (
            <div className={s.header_item} onClick={() => setShowNavMenu(!showNavMenu)(dispatch)}>
              <MenuIcon />
            </div>
          ) : (
            <div className={s.header_item}></div>
          )}

          <div className={s.logo}>
            <div className={s.circle}></div>
            <div className={s.logo_title}>{t('header.title')}</div>
            <div className={s.separator} />
            <div className={s.gray}>{getUserRole(user)}</div>
          </div>
          <div
            className={cn(s.header_item, s.mirror)}
            onClick={() => setShowHeaderMenu(!showHeaderMenu)(dispatch)}
          >
            <MenuIcon />
          </div>
        </div>
      ) : (
        <div className={s.header}>
          <div
            className={`${s.icon_container} ${!collapseNavMenu ? s.open : ''}`}
            onClick={onCollapseHandler}
          >
            <div className={`${s.icon} ${!collapseNavMenu ? s.open : ''}`}>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
          <div className={s.logo}>
            <div className={s.circle}></div>
            <div className={s.logo_title}>{t('header.title')}</div>
            <div className={s.separator} />
            <div className={s.gray}>{getUserRole(user)}</div>
          </div>
          <HeaderNavByRole user={user} />
          <HeadUserBlock />
        </div>
      )}
      <TechnicalTimeoutMW />
    </>
  )
}

export default React.memo(Header)
