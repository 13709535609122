import _ from 'lodash'
import React, { useState } from 'react'

import SwitchItem from './SwitchItem'

type Props = {
  selectAdditional: Function
  additionals: any
}

type SwitchProps = {
  item: any
  selectAdditional: Function
  additionals: any
}

const Switch = ({ item, selectAdditional, additionals }: SwitchProps) => {
  const [state, setState] = useState(false)

  return item.items.map((prod: any) => (
    <SwitchItem
      key={prod.id}
      title={prod.name}
      price={prod.cost}
      checked={state}
      setChecked={setState}
      data={prod}
      onClick={(add_id: number) => {
        selectAdditional(_.find(additionals, { id: item.id }), add_id, 1)
      }}
    />
  ))
}

const Additional = ({ selectAdditional, additionals }: Props) => {
  return (
    <>
      {additionals.map((item: any) => {
        if (item.items.length === 1) {
          return (
            <Switch
              item={item}
              selectAdditional={selectAdditional}
              additionals={additionals}
              key={item.id}
            />
          )
        }
        return null
      })}
    </>
  )
}

export default React.memo(Additional)
