import React from 'react'
import useResizeObserver from '@react-hook/resize-observer'

export type IsPortraitContextVal = boolean
export const IsPortraitOrientationContext = React.createContext<IsPortraitContextVal>(false)

const checkIsPortrait = (width: number, height: number) => width <= height

type Props = {
  children: React.ReactNode
}

export const Provider = ({ children }: Props): React.ReactElement => {
  const target = React.useRef<HTMLDivElement | null>(null)
  const [isPortrait, setIsPortrait] = React.useState<IsPortraitContextVal>(
    checkIsPortrait(window.screen.width, window.screen.height),
  )

  React.useLayoutEffect(() => {
    if (target.current) {
      setIsPortrait(checkIsPortrait(window.screen.width, window.screen.height))
    }
  }, [target])

  useResizeObserver(target, (entry) =>
    setIsPortrait(checkIsPortrait(window.screen.width, window.screen.height)),
  )

  return (
    <IsPortraitOrientationContext.Provider value={isPortrait}>
      <div ref={target}>{children}</div>
    </IsPortraitOrientationContext.Provider>
  )
}

export const { Consumer } = IsPortraitOrientationContext
