/* eslint-disable @typescript-eslint/naming-convention */
import { updateProductStatusRequest } from 'application/data/api/products'
import queryClient from 'application/data/apiClient/queryClient'
import { Product } from 'application/domain/entity/product/Product'
import { UpdateProductStatusDataType } from 'application/domain/entity/products/products'
import _ from 'lodash'
import { useMutation } from 'react-query'
import { ProductType, VarietyType, OptionType } from 'types/catalogType'

export const UpdateProductStatusMutation = (callback?: Function) => {
  const mutation = useMutation(
    ({ product_id, outlet_id, status }: UpdateProductStatusDataType) =>
      updateProductStatusRequest(product_id, outlet_id, status),
    {
      onMutate: async ({ product_id, status, client_product_id, outlet_id }) => {
        await queryClient.cancelQueries('products')
        await queryClient.cancelQueries('product')
        const previousData = queryClient.getQueryData<any>(['products', `outlet_${outlet_id}`])
        const prodPrevData = queryClient.getQueryData<any>([
          'product',
          client_product_id,
          outlet_id,
        ])
        if (previousData) {
          let newData = _.cloneDeep(previousData)
          newData.products = newData.products.map((item: ProductType) => {
            if (item.id === product_id) {
              // @ts-ignore
              item.availability_status = status
            }
            return item
          })
          queryClient.setQueryData(['products', `outlet_${outlet_id}`], newData)

          if (callback) {
            callback()
          }
        }
        if (prodPrevData) {
          let newData = _.cloneDeep(prodPrevData)
          newData.product.availability_status = status
          queryClient.setQueryData(['product', client_product_id, outlet_id], newData)
        }
        return { previousData }
      },
      onError: (err, variables, context: any) => {
        if (context?.previousData) {
          queryClient.setQueryData('products', context.previousData)
        }
      },
      onSettled: () => {
        // queryClient.invalidateQueries('products')
        // queryClient.invalidateQueries('product')
      },
    },
  )
  return mutation
}

type UpdateProductOptionStatusDataType = {
  product_id: number
  outlet_id: number
  option_id: number
  status: string
  mutatation_product_id?: number
}

export const UpdateProductOptionStatusMutation = (callback?: Function) => {
  const mutation = useMutation(
    ({ option_id, outlet_id, status, product_id }: UpdateProductOptionStatusDataType) =>
      updateProductStatusRequest(product_id, outlet_id, status, option_id),
    {
      onMutate: async ({ product_id, option_id, status, outlet_id, mutatation_product_id }) => {
        const prod_id = mutatation_product_id || product_id
        await queryClient.cancelQueries('products')
        await queryClient.cancelQueries('product')
        const previousData = queryClient.getQueryData<any>(['product', prod_id, outlet_id || 0])
        const productsPreviousData = queryClient.getQueryData<any>([
          'products',
          `outlet_${outlet_id || 0}`,
        ])
        if (previousData) {
          let newData = _.cloneDeep(previousData)
          newData.product.varieties = newData.product.varieties.map((item: VarietyType) => {
            if (item.variety_id === option_id) {
              //@ts-ignore
              item.availability_status = status
            }
            return item
          })
          newData.product.options = newData.product.options.map((item: OptionType) => {
            item.items = item.items.map((option) => {
              if (option.variety_id === option_id) {
                // @ts-ignore
                option.availability_status = status
              }
              return option
            })
            return item
          })
          queryClient.setQueryData(['product', prod_id, outlet_id || 0], newData)
        }

        if (productsPreviousData) {
          let newData = _.cloneDeep(productsPreviousData)
          newData.products = newData.products.map((product: Product) => {
            return {
              ...product,
              varieties:
                product.id === prod_id
                  ? product.varieties.map((item: VarietyType) => {
                      if (item.variety_id === option_id) {
                        //@ts-ignore
                        item.availability_status = status
                      }
                      return item
                    })
                  : product.varieties,
              options:
                product.id === prod_id
                  ? product.options.map((item: OptionType) => {
                      item.items = item.items.map((option) => {
                        if (option.variety_id === option_id) {
                          // @ts-ignore
                          option.availability_status = status
                        }
                        return option
                      })
                      return item
                    })
                  : product.options,
            }
          })
          queryClient.setQueryData(['products', `outlet_${outlet_id || 0}`], newData)
        }

        if (callback) {
          callback()
        }
        return { previousData }
      },
      onError: (err, variables, context: any) => {
        if (context?.previousData) {
          queryClient.setQueryData('product', context.previousData)
        }
      },
      onSettled: () => {
        // queryClient.invalidateQueries('product')
        // queryClient.invalidateQueries('products')
      },
    },
  )
  return mutation
}
