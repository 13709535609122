import { fetchOutletsAverageStats, fetchStatistics } from 'application/data/api/statistics'
import { useQuery } from 'react-query'

export const useStatisctics = (currentOutlet: any) => {
  const query = useQuery(['statistics', currentOutlet ? currentOutlet.id : 0], () =>
    fetchStatistics(currentOutlet ? currentOutlet.id : 0),
  )
  return query
}

export const useOutletsAverageStatistics = (config?: any) => {
  const query = useQuery(['outlets_average_statistics'], fetchOutletsAverageStats, {
    ...config,
  })
  return query
}
