import React, { useCallback, useMemo } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Order } from 'application/domain/entity/order/Order'
import { ChangeOrderStatusMutation } from 'application/domain/useCase/order/orderItemMutation'

import { Button } from '../../uiComponents'
import MWContainer from '../MWContainer'

import s from './PrintCheckMW.module.scss'

type Props = {
  lastCreatedOrder?: Order
}

const PrintCheckMW = ({ lastCreatedOrder }: Props) => {
  const changeStatusMutation = ChangeOrderStatusMutation()
  const { hash } = useLocation()
  const navigate = useNavigate()
  const opened = useMemo(() => {
    return hash.indexOf('print_check') !== -1
  }, [hash])

  const closeMW = useCallback(() => {
    changeStatusMutation.mutate(
      //@ts-ignore
      { open_order: lastCreatedOrder },
    )
    navigate('/kassa_panel', { replace: true })
  }, [navigate, lastCreatedOrder, changeStatusMutation])

  return (
    <MWContainer
      isOpen={opened}
      onRequestClose={() => {
        if (hash.indexOf('print_check') !== -1) {
          closeMW()
        }
      }}
      onClose={closeMW}
    >
      <div className={s.title}>Идет печать чека</div>
      <div className={s.subtitle}>
        По завершению печати <br />
        нажмите на кнопку
      </div>
      <Button text="ОК" color="green" containerClassName={s.btn} onClick={closeMW} />
    </MWContainer>
  )
}

export default React.memo(PrintCheckMW)
