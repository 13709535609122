import React from 'react'
import loadable from '@loadable/component'

type LazyRouteProps = {
  appMode: string
}

// BusinessPanelPage
const BusinessPanelPage = loadable(() => import('../pages/commonPages/BusinessPanelPage'))
const BookingBusinessPanelPage = loadable(
  () => import('../pages/bookingPages/BookingBusinessPanelPage'),
)
export const LazyBusinessPanelPage = React.memo(({ appMode }: LazyRouteProps) => {
  return appMode === 'booking' ? <BookingBusinessPanelPage /> : <BusinessPanelPage />
})

// OrderPanelPage
const BookingOrderPanelPage = loadable(() => import('../pages/bookingPages/BookingOrdersPanelPage'))
const BaristaPanelPage = loadable(() => import('../pages/commonPages/BaristaPanelPage'))
export const LazyOrderPanelPage = React.memo(({ appMode }: LazyRouteProps) => {
  return appMode === 'booking' ? <BookingOrderPanelPage /> : <BaristaPanelPage />
})

// BookingOrderPage
const BookingOrderPage = loadable(() => import('../pages/bookingPages/BookingOrderPage'))
export const LazyBookingOrderPage = React.memo(({ appMode }: LazyRouteProps) => {
  return appMode === 'booking' ? <BookingOrderPage /> : <></>
})

// KassaPanelPage
const BookingKassaPanelPage = loadable(() => import('../pages/bookingPages/BookingKassaPanelPage'))
const KassaPanelPage = loadable(() => import('../pages/commonPages/KassaPanelPage'))
export const LazyKassaPanelPage = React.memo(({ appMode }: LazyRouteProps) => {
  return appMode === 'booking' ? <BookingKassaPanelPage /> : <KassaPanelPage />
})
