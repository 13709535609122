import _ from 'lodash'
import moment from 'moment'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router'

import { UniversalInputComponent } from '../../InputComponents'
import { Button } from '../../uiComponents'
import MWContainer from '../MWContainer'
import { getRandomInRange } from '../ProductMW/BasketActions'

import s from './AddUnusualConsumableMW.module.scss'

const meraTypeData = [
  {
    label: 'Штука',
    value: 1,
  },
  {
    label: 'Литр',
    value: 2,
  },
  {
    label: 'Грамм',
    value: 3,
  },
  {
    label: 'КГ',
    value: 4,
  },
  {
    label: 'Упаковка',
    value: 5,
  },
]

type Props = {
  addProduct: Function
}

const AddUnusualConsumableMW = ({ addProduct }: Props) => {
  const { t } = useTranslation()
  const { hash } = useLocation()
  const navigate = useNavigate()
  const opened = useMemo(() => {
    return hash.indexOf('add_unusual_consumable') !== -1
  }, [hash])

  const [name, setName] = useState<string>('')
  const [count, setCount] = useState<string>('')
  const [mera, setMera] = useState<number>(0)
  const add = useCallback(() => {
    const m = _.find(meraTypeData, { value: mera })
    addProduct({
      name,
      size: parseInt(count, 10),
      measure: m?.label,
      isUnusual: true,
      id: parseInt(`${moment().unix()}${getRandomInRange(1, 1000)}`),
    })
    setName('')
    setCount('')
    setMera(0)
    navigate(-1)
  }, [addProduct, name, count, mera, navigate])
  return (
    <MWContainer
      isOpen={opened}
      color="white"
      onRequestClose={() => {
        if (hash.indexOf('add_unusual_consumable') !== -1) {
          navigate(-1)
        }
      }}
    >
      <div className={s.title}>Добавление необычного расходника</div>
      <div className={s.content}>
        <UniversalInputComponent
          type="text"
          title={t('input.name') || ''}
          value={name}
          onChange={setName}
        />
        <UniversalInputComponent
          type="number"
          title={t('input.count') || ''}
          value={count}
          onChange={setCount}
        />
        <UniversalInputComponent
          type="selector"
          title={t('input.mera') || ''}
          value={mera}
          onChange={setMera}
          options={meraTypeData}
        />
        <Button text="Добавить" onClick={add} color="green" className={s.btn} />
      </div>
    </MWContainer>
  )
}

export default React.memo(AddUnusualConsumableMW)
