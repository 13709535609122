import Header from 'application/presentation/common/Header'
import React, { useMemo, useState } from 'react'

type HeaderFooterContextType = {
  showHeader: (show: boolean) => void
} | null

export const HeaderFooterContext = React.createContext<HeaderFooterContextType>(null)

export const HeaderFooterProvider = ({ children }: any) => {
  const [showHeader, setShowHeader] = useState<boolean>(true)

  const headerFooterFunc = useMemo(
    () => ({
      showHeader: (show: boolean) => {
        setShowHeader(show)
      },
    }),
    [],
  )

  return (
    <HeaderFooterContext.Provider value={headerFooterFunc}>
      {showHeader && <Header />}
      {children}
    </HeaderFooterContext.Provider>
  )
}

export const { Consumer } = HeaderFooterContext
