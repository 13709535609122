/* eslint-disable @typescript-eslint/naming-convention */
import { ClientOutletsAppliedSettings, Outlet } from 'application/domain/entity/outlet/Outlet'
import moment from 'moment'
import _find from 'lodash/find'
import _findLast from 'lodash/findLast'
import _isArray from 'lodash/isArray'
import _map from 'lodash/map'

export const checkOutletWorkTime = (bar: Outlet) => {
  let time_work = bar.time_work
  const now = moment()
  time_work = time_work.replace(/\s+/g, '').trim()
  let splitted = time_work.split('-')
  if (!splitted[1]) {
    splitted = time_work.split('–')
  }
  const start = splitted[0]
  const end = splitted[1]
  let endTime = moment().format('YYYY-MM-DD')
  const startTime = `${endTime} ${start}`
  endTime = `${endTime} ${end}`
  const endDate = moment(endTime)
  const startDate = moment(startTime)
  return now.isBefore(startDate) || endDate.diff(now, 'minutes') <= 10 ? true : false
}

export type OutletWorkInterval = {
  startWork: {
    hour: number
    minutes: number
    date: moment.Moment
  }
  endWork: {
    hour: number
    minutes: number
    date: moment.Moment
  }
  hours: number
}

export const getOutletWorkInterval = (outlet: Outlet): OutletWorkInterval => {
  let time_work = outlet.time_work
  const now = moment()
  time_work = time_work.replace(/\s+/g, '').trim()
  let splitted = time_work.split('-')
  if (!splitted[1]) {
    splitted = time_work.split('–')
  }
  const start = moment(`${now.format('YYYY-MM-DD')} ${splitted[0]}`)
  const end = moment(`${now.format('YYYY-MM-DD')} ${splitted[1]}`)
  const hours = end.diff(start, 'hours')
  return {
    startWork: {
      hour: start.get('hours'),
      minutes: start.get('minutes'),
      date: start,
    },
    endWork: {
      hour: end.get('hours'),
      minutes: end.get('minutes'),
      date: end,
    },
    hours: hours,
  }
}

export const getTechnicalBreak = (outlet: Outlet): string | null => {
  if (outlet && outlet.technical_break && outlet.technical_break.indexOf('00:00') !== -1) {
    return null
  }
  return outlet.technical_break || null
}

export const getOutletAppliedParameter = (
  outlet: Outlet | null,
  parameter_name: string,
): ClientOutletsAppliedSettings | null => {
  if (outlet && outlet.client_outlets_applied_settings.length) {
    const f = _findLast(outlet.client_outlets_applied_settings, (item) => {
      return item.default
        ? item.default.parameter_name === parameter_name
        : item.parameter_name === parameter_name
    })
    return f ? { ...f, value: f.value ? f.value : f?.default ? f.default.default_value : '' } : null
  }
  return null
}

export const getOutletAppliedParameters = (
  outlet: Outlet | null,
  parameters_name: Array<string>,
) => {
  if (outlet && outlet.client_outlets_applied_settings.length) {
    let resObj: any = {}
    _map(outlet.client_outlets_applied_settings, (item) => {
      if (item.default && parameters_name.indexOf(item.default.parameter_name) !== -1) {
        resObj[`${item.default.parameter_name}`] = {
          ...item,
          value: item.value ? item.value : item?.default ? item.default.default_value : '',
        }
      } else if (item.parameter_name && parameters_name.indexOf(item.parameter_name) !== -1) {
        resObj[`${item.parameter_name}`] = {
          ...item,
          value: item.value ? item.value : item?.default ? item.default.default_value : '',
        }
      }
    })
    return resObj
  }
  return null
}
