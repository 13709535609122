import { RootState } from 'application/domain/store/store'
import React from 'react'
import { useSelector } from 'react-redux'

import { HeaderNavByRole } from './Header'

const PortraitHeaderMenu = () => {
  const { user } = useSelector(({ userReducer }: RootState) => ({
    user: userReducer.user,
  }))

  return <HeaderNavByRole user={user} />
}

export default React.memo(PortraitHeaderMenu)
