import { getOrderNewStatus } from 'application/presentation/common/PureFunctions/getOrderStatus'
import { OrderType } from 'types/orderType'
import {
  ChangeOrderBookingDateData,
  ChangeOrderPositionsData,
} from 'application/domain/types/requestTypes'

import apiClient from '../apiClient'

export const fetchOrders = async () => {
  const { data } = await apiClient.get('/api/orders/getLastOrders')
  return data
}

export const fetchCheckAts = async () => {
  const { data } = await apiClient.get(
    window.ENV && window.ENV.ENVIRMENT === 'stage' ? '/api/checkAtsStage' : '/api/checkAts',
  )
  return data
}

export const changeOrderStatusRequest = async (open_order: OrderType) => {
  const newStatus = getOrderNewStatus(open_order.status)
  const id = open_order.open_order_id ? open_order.open_order_id : open_order.id
  const { data } = await apiClient.post(
    `/api/orders/changeStatusOpenOrders${newStatus.rusStatus === 'Завершен' ? `?o_id=${id}` : ''}`,
    {
      open_order: {
        ...open_order,
        id: id,
        status: newStatus.rusStatus,
      },
    },
  )
  return data
}

export const updateOrderItemRequest = async (orderItemId: number, method: string) => {
  const { data } = await apiClient.post('/api/orders/updateLastOrder', {
    orderItemId: orderItemId,
    order_method: method,
  })
  return data
}

export const createOrderRequest = async (sendData: any) => {
  const { data } = await apiClient.post('/api/barista/sendDistanceOrder', sendData)
  return data
}

export const cancelOpenOrderRequest = async (sendData: {
  open_order_id: number
  comment: string
}) => {
  const { data } = await apiClient.post('/api/barista/orders/open/cancel', sendData)
  return data
}

export const fetchAdminOrders = async () => {
  const { data } = await apiClient.get('/api/admin/orders?panel=true')
  return data
}

export const changeOrderPaymentMethodRequest = async (
  open_order_id: number,
  paymentMethod: string,
) => {
  const { data } = await apiClient.post('/api/orders/changePaymentMethod', {
    open_order_id,
    payment_type: paymentMethod,
  })
  return data
}

export const changeOrderPaymentStatusRequest = async (
  open_order_id: number,
  payment_status: string,
) => {
  const { data } = await apiClient.post('/api/barista/orders/paymentStatus', {
    payment_status,
    open_order_id,
  })
  return data
}

export const setOrderExtraSessionRequest = async (
  open_order_id: number,
  extra_session_id: number,
  isComletedOrder?: boolean,
) => {
  const sendData: { extra_session_id: number; order_id?: number; open_order_id?: number } = {
    extra_session_id,
  }
  if (isComletedOrder) {
    sendData.order_id = open_order_id
  } else {
    sendData.open_order_id = open_order_id
  }
  const { data } = await apiClient.post('/api/barista/orders/setExtraSession', sendData)
  return data
}

export const changeOrderPositionsRequest = async (sendData: ChangeOrderPositionsData) => {
  const { data } = await apiClient.post('/api/barista/changePosOrder', sendData)
  return data
}

export const changeOrderBookingDateRequest = async (sendData: ChangeOrderBookingDateData) => {
  const { data } = await apiClient.put(`api/barista/booking/${sendData.booking_id}`, {
    booking_date: sendData.booking_date,
    table_id: sendData.table_id,
  })
  return data
}

export const remakeOrderRequest = async (sendData: any) => {
  const { data } = await apiClient.post('/api/barista/erp/sendWriteOffForProduct', sendData)
  return data
}

export const fetchSecretAdminOrdersRequest = async (sendData: {
  date_from?: string
  date_to?: string
  type?: string
  outlet_id?: string
}) => {
  const { data } = await apiClient.get('/api/get_my_secret_admin_orders', {
    params: sendData,
  })
  return data
}

export type CanceledOrder = {
  [key: string]: any
}

export const fectCanceledOrdersRequest = async (outletId: string) => {
  const { data } = await apiClient.get<CanceledOrder[]>('/api/get_canceled_orders', {
    params: {
      outlet_id: outletId,
    },
  })

  return data
}
