import React from 'react'
import NavSvg from 'images/icons/nav_stat.svg'

import NavigationItems from '../NavigationItems'

export const superAdminRoutes = [
  {
    id: 1,
    name: 'Статистика',
    t_name: 'statistics',
    icon: NavSvg,
    items: [
      { name: 'Обработанные заказы', link: '/orders_statistic' },
      { name: 'Отмененные заказы', link: '/canceled_orders' },
    ],
  },
]

const SuperAdminMenu = () => {
  return <NavigationItems data={superAdminRoutes} />
}

export default React.memo(SuperAdminMenu)
