import React from 'react'

import s from './EmptyPageContent.module.scss'

export type EmptyPageContentProps = {
  title?: string
  subtitle?: string
  btnConfig?: {
    title: string
    onClick: any
  }
}

const EmptyPageContent = ({
  title = 'Таблица пуста',
  subtitle,
  btnConfig,
}: EmptyPageContentProps) => {
  return (
    <div className={s.container}>
      <div className={s.inner_content}>
        <div className={s.title}>{title}</div>
        {subtitle ? <div className={s.subtitle}>{subtitle}</div> : <></>}
        {btnConfig ? (
          <div className={s.button} onClick={btnConfig.onClick}>
            {btnConfig.title}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

export default React.memo(EmptyPageContent)
