import React from 'react'
import { HeadProvider } from 'react-head'
import { QueryClientProvider } from 'react-query'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import queryClient from 'application/data/apiClient/queryClient'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

import store from './application/domain/store/store'
import { Authorization as AuthProvider } from './application/app/AuthContext'
import { Provider as IsPortraitOrientationProvider } from './application/app/isPortraitOrientation'
import { Provider as IsMobileProvider } from './application/app/IsMobile'
import { ErrorHandler } from './application/presentation/ErrorBoundary'
import AppView from './application/presentation/AppView'
import 'react-toastify/dist/ReactToastify.css'
import 'moment/locale/ru'
import './app.scss'
import 'swiper/css'
import 'react-tooltip/dist/react-tooltip.css'

import './i18n'

Bugsnag.start({
  apiKey: '52283f7de5a02983b3740886328a7519',
  plugins: [new BugsnagPluginReact()],
})

function App() {
  return (
    <ErrorHandler>
      <HeadProvider>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <Provider store={store}>
              <AuthProvider>
                <IsMobileProvider>
                  <IsPortraitOrientationProvider>
                    <AppView />
                  </IsPortraitOrientationProvider>
                </IsMobileProvider>
              </AuthProvider>
            </Provider>
          </BrowserRouter>
        </QueryClientProvider>
      </HeadProvider>
    </ErrorHandler>
  )
}

export default App
