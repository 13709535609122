/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback, useMemo, useState } from 'react'
import _ from 'lodash'
import cn from 'classnames'
import { Tooltip } from 'react-tooltip'
import { useLocation, useNavigate } from 'react-router'

import Button from '../Button'
import EmptyPageContent, { EmptyPageContentProps } from '../EmptyPageContent/EmptyPageContent'
import { UniversalInputComponent } from '../../InputComponents'

import s from './Table.module.scss'

export type HeadItemType = {
  name: string
  value_key: string | Array<string>
  type?: 'input_number'
  columnClassName?: any
}

type Props = {
  headData: Array<HeadItemType>
  data: Array<any>
  withRowNumber?: boolean
  tableClassName?: string
  itemIdAsRowNumber?: boolean
  withPagination?: boolean
  perPage?: number
  totalBy?: Array<string>
  tableRowClassName?: string
  onRowClick?: (item: any) => void
  tooltipKey?: string
  tooltipName?: string
  bigTable?: boolean
  emptyContentData?: EmptyPageContentProps
}

const parseText = (txt: string) => {
  return txt && typeof txt === 'string'
    ? txt.split('\n').map((str, i) => <p key={i}>{str}</p>)
    : txt
}

const Table = ({
  headData,
  data,
  withRowNumber,
  tableClassName,
  itemIdAsRowNumber,
  withPagination,
  perPage = 20,
  totalBy,
  tableRowClassName,
  onRowClick,
  tooltipKey,
  tooltipName,
  bigTable,
  emptyContentData,
}: Props) => {
  const { pathname, search, hash } = useLocation()
  const navigate = useNavigate()

  const pageParams = useMemo(() => {
    const params = new URLSearchParams(search)
    const p = params.get('page')
    return p ? parseInt(p, 10) : 1
  }, [search])

  const [page, setPage] = useState<number>(pageParams)
  const [pageNum, setPageNum] = useState<number>(page || 1)

  const pageCount = useMemo(() => {
    const count = Math.ceil(data.length / perPage)
    return count || 1
  }, [data, perPage])

  const tableData = useMemo(() => {
    if (!withPagination) {
      return data
    }
    return _.slice(data, perPage * (page - 1), perPage * page)
  }, [withPagination, data, page, perPage])

  const totalRowData = useMemo(() => {
    if (totalBy) {
      const total = headData.map((item) => {
        if (totalBy.indexOf(item.value_key.toString()) !== -1) {
          return _.sumBy(tableData, item.value_key.toString())
        }
        return null
      })
      return total
    }
    return null
  }, [totalBy, tableData, headData])

  const nextPageClickHandler = useCallback(() => {
    setPage(page + 1 <= pageCount ? page + 1 : page)
    setPageNum(page + 1 <= pageCount ? page + 1 : page)
    navigate(`${pathname}?page=${page + 1 <= pageCount ? page + 1 : page}`, { replace: true })
  }, [navigate, page, pageCount, pathname])

  const prevPageClickHandler = useCallback(() => {
    setPage(page - 1)
    setPageNum(page - 1)
    navigate(`${pathname}?page=${page - 1}`, { replace: true })
  }, [navigate, page, pathname])

  const onRowClickHandler = useCallback(
    (item: any) => {
      if (onRowClick) {
        onRowClick(item)
      }
    },
    [onRowClick],
  )

  const goPageHandler = useCallback(() => {
    setPage(pageNum)
    navigate(`${pathname}?page=${pageNum}`, { replace: true })
  }, [navigate, pageNum, pathname])

  return (
    <div className={cn(s.table, { [s.big_table]: bigTable }, tableClassName || '')}>
      {withPagination && (
        <div className={s.table_pagination}>
          <div className={s.pagintaion_info}>
            <UniversalInputComponent
              title=""
              type="number"
              value={pageNum}
              onChange={(val: number) => setPageNum(val)}
              containerClassName={s.input_page_container}
            />
            <Button
              disabled={pageNum < 1 || pageNum > pageCount}
              color="green"
              text="Перейти"
              containerClassName={`${s.pagination_btn_container} ${s.btn_page}`}
              onClick={goPageHandler}
            />

            <div className={s.pagination_text}>
              Страница {page} из {pageCount}
            </div>
          </div>
          <Button
            disabled={page <= 1}
            color="orange"
            text="Назад"
            containerClassName={s.pagination_btn_container}
            onClick={prevPageClickHandler}
          />

          <Button
            color="green"
            text="Далее"
            containerClassName={s.pagination_btn_container}
            onClick={nextPageClickHandler}
          />
        </div>
      )}
      <div className={s.table_head}>
        {withRowNumber && <div className={s.row_number}>#</div>}
        {headData.map((item: HeadItemType, index: number) => (
          <div
            className={cn(s.head_item, item.columnClassName || '')}
            key={`head_item_${item.name || index}`}
          >
            {item.name}
          </div>
        ))}
      </div>
      <div className={s.table_body}>
        {tableData && tableData.length ? (
          tableData.map((body_item: any, i: number) => {
            return (
              <div
                className={`${s.table_row} ${onRowClick ? s.clickable : ''}${
                  tableRowClassName || ''
                } ${body_item.tableRowClassName || ''}`}
                key={`table_row_${i}_${body_item.id ? body_item.id : body_item.value_key}`}
                onClick={() => onRowClickHandler(body_item)}
              >
                {withRowNumber && (
                  <div className={s.row_number}>{itemIdAsRowNumber ? body_item.id : i + 1}</div>
                )}
                {headData.map((head_item: HeadItemType, i2: number) => {
                  let value = ''
                  if (Array.isArray(head_item.value_key)) {
                    head_item.value_key.map((value_key_item: string) => {
                      value += `${_.get(body_item, value_key_item)} `
                    })
                  } else {
                    value = _.get(body_item, head_item.value_key)
                  }
                  return (
                    <React.Fragment
                      key={`table_row_item_${body_item.id}_${head_item.value_key}_${i}_${i2}`}
                    >
                      {tooltipKey && body_item[tooltipKey] !== null && (
                        <Tooltip
                          anchorSelect={`${'.info-tooltip' + head_item.value_key + '_' + i}`}
                          place="top"
                          className={s.tooltip}
                        >
                          <div className={s.tooltip_name}>{tooltipName}</div>
                          <div className={s.tooltip_info}>{body_item[tooltipKey]}</div>
                        </Tooltip>
                      )}
                      <div
                        className={cn(
                          s.row_item,
                          head_item.columnClassName || '',
                          tooltipKey === head_item.value_key
                            ? `${'info-tooltip' + `${head_item.value_key + '_' + i}`}`
                            : '',
                        )}
                      >
                        {parseText(value)}
                        {/* {value} */}
                      </div>
                    </React.Fragment>
                  )
                })}
              </div>
            )
          })
        ) : (
          <EmptyPageContent
            title={emptyContentData?.title}
            subtitle={emptyContentData?.subtitle}
            btnConfig={emptyContentData?.btnConfig}
          />
        )}
      </div>
      {totalRowData ? (
        <div className={s.total_row}>
          {totalRowData.map((item, i: number) => (
            <div className={cn(s.total_item)} key={`total_item_${i}`}>
              {item ? item : ''}
            </div>
          ))}
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

export default React.memo(Table)
