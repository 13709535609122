import React from 'react'
import cn from 'classnames'

import s from './EmptyClientItem.module.scss'

type Props = {
  listIsEmpty?: boolean
  onClick: Function
}

const EmptyClientItem = ({ listIsEmpty, onClick }: Props) => {
  return (
    <div
      className={cn(s.container, { [s.large]: listIsEmpty })}
      //@ts-ignore
      onClick={onClick}
    >
      <div className={s.text}>
        {listIsEmpty
          ? 'Клиенты с данным авто не найдены, продолжите чтобы добавить.'
          : 'Добавить авто и клиента'}
      </div>
    </div>
  )
}

export default React.memo(EmptyClientItem)
