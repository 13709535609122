import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { ReactComponent as PlusSvg } from 'images/icons/blue_plus.svg'
import _ from 'lodash'
import cn from 'classnames'
import { IsPortraitOrientationContext } from 'application/app/isPortraitOrientation'

import s from '../Filters.module.scss'
import { FilterDataType, FilterItemType } from '../Filters'
import Button from '../../Button'

import FilterItemContent from './FilterItemContent'

type Props = {
  data: FilterDataType
  onFilterItemChange: (value: any, filterIndex: number, filterItemIndex: number) => void
  onFilterItemSubmit: (index: number) => void
}

type FilterItemProps = { data: FilterItemType; onClick: Function; selectedFilter: number }

const FilterItem = React.memo(({ data, selectedFilter, onClick }: FilterItemProps) => {
  return (
    <div>
      <div
        key={`filter_item_inner_${data.id}`}
        className={cn(s.dropdown_variety, selectedFilter === data.id ? s.selected : '')}
        onClick={() => onClick(data.id)}
      >
        {data.name}
      </div>
    </div>
  )
})

const useOutsideClick = (ref: any, setOpened: Function) => {
  useEffect(() => {
    const handleOutsideClick = (e: any) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setOpened(false)
      }
    }
    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [ref, setOpened])
}

const AddFilter = ({ data, onFilterItemChange, onFilterItemSubmit }: Props) => {
  const isPortrait = useContext(IsPortraitOrientationContext)
  const containerRef = useRef<any>()
  const wrapperRef = useRef<any>()
  const [opened, setOpened] = useState<boolean>(false)
  const [fromRight, setFromRight] = useState<boolean>(true)
  const [selectedFilter, setSelectedFilter] = useState<number>(0)
  const [selectedFilterIndex, setSelectedFilterIndex] = useState<number>(0)
  useOutsideClick(wrapperRef, setOpened)

  useEffect(() => {
    if (!opened) {
      setSelectedFilter(0)
      setSelectedFilterIndex(0)
    }
    if (opened) {
      const elOffsets = wrapperRef.current.getBoundingClientRect()
      if (elOffsets.width + elOffsets.left + 200 > window.innerWidth) {
        setFromRight(true)
      } else {
        setFromRight(false)
      }
    }

    // console.log(wrapperRef.current.getBoundingClientRect())
  }, [opened])

  useEffect(() => {
    if (selectedFilter) {
      setSelectedFilterIndex(_.findIndex(data, { id: selectedFilter }))
    }
  }, [selectedFilter, data])

  const handleClick = useCallback(() => {
    setOpened((o) => !o)
  }, [])

  const handleClickItem = useCallback((id: number) => {
    setSelectedFilter(id)
  }, [])

  const handleFilterItemChange = useCallback(
    (value: any, index: number) => onFilterItemChange(value, selectedFilterIndex, index),
    [selectedFilterIndex, onFilterItemChange],
  )

  const onSubmitItem = useCallback(() => {
    onFilterItemSubmit(selectedFilterIndex)
    setOpened(false)
  }, [onFilterItemSubmit, selectedFilterIndex])

  return (
    <div className={s.dropdown_container} ref={containerRef}>
      <div className={s.filter_item} onClick={handleClick}>
        <PlusSvg /> <span>Фильтр</span>
      </div>
      <div
        className={`${s.dropdown_menu} ${s.long} ${fromRight ? s.from_right : ''} ${
          opened ? s.opened : ''
        }`}
        ref={wrapperRef}
        style={
          isPortrait
            ? {
                top: `${
                  containerRef.current ? containerRef.current.getBoundingClientRect().top : 0
                }px`,
              }
            : {}
        }
      >
        {isPortrait && selectedFilter ? (
          <></>
        ) : (
          <div className={s.filters}>
            {data.map((item) => {
              return item.hidden ? (
                <React.Fragment key={`filter_item_${item.id}`}></React.Fragment>
              ) : (
                <FilterItem
                  key={`filter_item_${item.id}`}
                  data={item}
                  onClick={handleClickItem}
                  selectedFilter={selectedFilter}
                />
              )
            })}
          </div>
        )}

        {selectedFilter ? (
          <div className={s.filter_content}>
            {isPortrait && (
              <Button
                text="Назад"
                color="orange"
                containerClassName={s.back_btn}
                onClick={() => {
                  setSelectedFilter(0)
                  setSelectedFilterIndex(0)
                }}
              />
            )}
            <FilterItemContent
              data={_.find(data, { id: selectedFilter })}
              onFilterItemChange={handleFilterItemChange}
              onSubmit={onSubmitItem}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

export default React.memo(AddFilter)
