import _ from 'lodash'
import moment from 'moment'
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { ReactComponent as DeleteIcon } from 'images/icons/close.svg'
import { IsPortraitOrientationContext } from 'application/app/isPortraitOrientation'

import { FilterItemType } from '../Filters'
import s from '../Filters.module.scss'

import FilterItemContent from './FilterItemContent'

type Props = {
  data: FilterItemType
  filterData: FilterItemType
  onRemoveItemClick: () => void
  onFilterItemChange: (value: any, filterIndex: number, filterItemIndex: number) => void
  onFilterItemSubmit: (index: number) => void
}

const useOutsideClick = (ref: any, setOpened: Function) => {
  useEffect(() => {
    const handleOutsideClick = (e: any) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setOpened(false)
      }
    }
    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [ref, setOpened])
}

const SubmittedFilter = ({
  data,
  filterData,
  onRemoveItemClick,
  onFilterItemChange,
  onFilterItemSubmit,
}: Props) => {
  const isPortrait = useContext(IsPortraitOrientationContext)
  const containerRef = useRef<any>()
  const wrapperRef = useRef<any>()
  const [opened, setOpened] = useState<boolean>(false)
  const [fromRight, setFromRight] = useState<boolean>(false)
  useOutsideClick(wrapperRef, setOpened)
  const handleClick = useCallback(() => {
    setOpened((o) => !o)
  }, [])

  const parsedValue = useMemo(() => {
    switch (data.type) {
      case 'selector':
        return _.find(data.data, { value: data.value })?.name
      case 'date_interval':
        if (data.value[0] && data.value[1]) {
          return `${moment(data.value[0]).format('DD.MM.YYYY')} - ${moment(data.value[1]).format(
            'DD.MM.YYYY',
          )}`
        }
        return data.value[0]
          ? `От ${moment(data.value[0]).format('DD.MM.YYYY')}`
          : `До ${moment(data.value[1]).format('DD.MM.YYYY')}`
      default:
        return data.value
    }
  }, [data])

  const handleFilterItemChange = useCallback(
    (value: any, index: number) => onFilterItemChange(value, data.index || 0, index),
    [data, onFilterItemChange],
  )

  const onSubmitItem = useCallback(() => {
    onFilterItemSubmit(data.index || 0)
    setOpened(false)
  }, [onFilterItemSubmit, data])

  useEffect(() => {
    if (opened) {
      const elOffsets = wrapperRef.current.getBoundingClientRect()
      if (elOffsets.width + elOffsets.left > window.innerWidth - 300) {
        setFromRight(true)
      } else {
        setFromRight(false)
      }
    }
  }, [opened])

  return (
    <div className={s.dropdown_container} ref={containerRef}>
      <div className={`${s.filter_item} ${s.selected}`} onClick={handleClick}>
        <span>
          {data.name}: {parsedValue}
        </span>
      </div>
      <div
        className={`${s.dropdown_menu} ${s.long} ${fromRight ? s.from_right : ''} ${
          opened ? s.opened : ''
        }`}
        style={
          isPortrait
            ? {
                top: `${
                  containerRef.current ? containerRef.current.getBoundingClientRect().top : 0
                }px`,
              }
            : {}
        }
        ref={wrapperRef}
      >
        <div className={s.submitted_filter_content}>
          <FilterItemContent
            data={filterData}
            onFilterItemChange={handleFilterItemChange}
            onSubmit={onSubmitItem}
            withRemove
            removeItem={onRemoveItemClick}
          />
        </div>
      </div>
    </div>
  )
}

export default React.memo(SubmittedFilter)
