import {
  fetchCategories,
  fetchProduct,
  fetchProductSelfcostDynamicRequest,
  fetchProducts,
  regionalPriceRequest,
} from 'application/data/api/products'
import { RootState } from 'application/domain/store/store'
import { CallbackType } from 'application/domain/types/callback'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'

export const getProducts = fetchProducts

export const useProducts = (outlet_id?: number) => {
  const { user } = useSelector(({ userReducer }: RootState) => ({
    user: userReducer.user,
  }))
  const outlet =
    outlet_id || outlet_id === 0 ? outlet_id : user && user.session ? user.session.outlet_id : 0
  const query = useQuery(['products', `outlet_${outlet}`], () => fetchProducts(outlet), {
    enabled: user ? true : false,
    refetchInterval: 60000,
    refetchOnWindowFocus: false,
  })
  return query
}

export const useCategories = (outlet_id: number) => {
  const query = useQuery('categories', () => fetchCategories(outlet_id), {
    refetchInterval: 60000,
    refetchOnWindowFocus: false,
  })
  return query
}

export const useProduct = (id: number, outlet_id?: number, config?: any) => {
  const query = useQuery(['product', id, outlet_id || 0], () => fetchProduct(id, outlet_id), {
    staleTime: 3300000,
    cacheTime: 3600000,
    ...config,
  })
  return query
}

export const editRegionalPrice = async ({
  onSuccess,
  onError,
  region_id,
  amount,
  variety_id,
}: {
  region_id: number
  amount: string
  variety_id: number
} & CallbackType) => {
  try {
    const data = await regionalPriceRequest(region_id, amount, variety_id)
    return onSuccess(data)
  } catch (e) {
    return onError(e)
  }
}

export const fetchProductSelfcostDynamic = fetchProductSelfcostDynamicRequest
