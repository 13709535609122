import { setToken, setUser } from 'application/domain/store/reducers/userSlice'
import React from 'react'
import { useDispatch } from 'react-redux'
import {
  login,
  registration,
  sendEmailPasswordReset,
} from 'application/domain/useCase/user/userActions'

import apiClient from '../../data/apiClient'

export type AuthContextType = {
  login: Function
  registration: Function
  logout: Function
  sendPasswordResetRequest: Function
}

type AuthDataType = {
  data: LoginDataType
  onStart?: Function
  onSuccess?: Function
  onError?: Function
}

type RegDataType = {
  data: RegistrationDataType
  onStart?: Function
  onSuccess?: Function
  onError?: Function
}

type SendEmailPasswordResetDataType = {
  data: {
    email: string
  }
  onStart?: Function
  onSuccess?: Function
  onError?: Function
}

type RegistrationDataType = {
  name: string
  password: string
  password_confirmation: string
  email: string
  // phone: string
}

type LoginDataType = {
  phone: string
  password: string
}

export const AuthContext = React.createContext<AuthContextType>({
  login: () => {},
  logout: () => {},
  registration: () => {},
  sendPasswordResetRequest: () => {},
})

export const Authorization = ({ children }: any) => {
  const dispatch = useDispatch()
  const auth = React.useMemo(
    () => ({
      login: async ({ data, onError, onStart, onSuccess }: AuthDataType) => {
        if (onStart) {
          onStart()
        }
        let authDat = {
          phone: data.phone,
          password: data.password,
        }
        try {
          const loginResponse = await login(authDat)
          if (loginResponse) {
            if (loginResponse.status === 'OK') {
              if (onSuccess) {
                setToken(loginResponse.data.token)(dispatch)
                setUser(loginResponse.data.user)(dispatch)
                //@ts-ignore
                apiClient.defaults.headers.Authorization = `Bearer ${loginResponse.data.token}`
                localStorage.setItem('user_token', loginResponse.data.token)
                localStorage.setItem('phone', authDat.phone)
                localStorage.setItem('password', authDat.password)
                onSuccess(loginResponse.data)
              }
              return loginResponse.data
            } else {
              if (onError) {
                onError(loginResponse.data.message)
              }
              return null
            }
          }
        } catch (error: any) {
          if (onError) {
            if (error && error.response && error.response.data) {
              if (error.response.data.message) {
                onError(error.response.data.message)
              } else {
                try {
                  onError(JSON.stringify(error.response.data))
                } catch (e) {
                  onError(error.response.data.toString())
                }
              }
            } else {
              try {
                onError(error && error.response ? error.response.toString() : '')
              } catch (e) {
                onError(e)
              }
            }
          }
          return null
        }
      },
      registration: async ({ data, onError, onStart, onSuccess }: RegDataType) => {
        if (onStart) {
          onStart()
        }
        let authDat = {
          name: data.name,
          password: data.password,
          password_confirmation: data.password_confirmation,
          email: data.email,
          // phone: data.phone,
        }

        try {
          const regResponse = await registration(authDat)
          if (regResponse) {
            if (regResponse.register.status === true) {
              return await auth.login({
                data: {
                  password: data.password,
                  phone: data.email,
                },
                onStart,
                onSuccess,
                onError,
              })
            } else {
              if (onError) {
                onError(regResponse.data.message)
              }
              return null
            }
          }
        } catch (error: any) {
          if (onError) {
            if (error && error.response && error.response.data) {
              onError(error.response.data.message)
            } else {
              onError('error')
            }
          }
          return null
        }
      },
      sendPasswordResetRequest: async ({
        data,
        onError,
        onStart,
        onSuccess,
      }: SendEmailPasswordResetDataType) => {
        if (onStart) {
          onStart()
        }
        try {
          const response = await sendEmailPasswordReset(data.email)
          if (response) {
            if (response.sendResetLinkEmail.status === true) {
              if (onSuccess) {
                onSuccess(response)
              }
              return response
            } else {
              if (onError) {
                onError(response.sendResetLinkEmail.message)
              }
            }
            return response
          }
        } catch (error: any) {
          if (onError) {
            if (error && error.response && error.response.data) {
              onError(error.response.data.message)
            } else {
              onError('error')
            }
          }
          return null
        }
      },
      logout: async (callback?: Function) => {
        localStorage.removeItem('user_token')
        localStorage.removeItem('phone')
        localStorage.removeItem('password')
        setToken('')(dispatch)
        setUser(null)(dispatch)

        //@ts-ignore
        apiClient.defaults.headers.Authorization = ``
        if (callback) {
          callback()
        }
      },
    }),
    [dispatch],
  )

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}
