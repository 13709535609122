import { useState, useEffect } from 'react'
import { Car } from 'application/domain/entity/car/Car'
import { Outlet } from 'application/domain/entity/outlet/Outlet'
import { defaultCarTypes } from 'application/presentation/constants'
import _find from 'lodash/find'
import _mapKeys from 'lodash/mapKeys'
import carsDB from 'application/data/localData/cars.json'
import moment from 'moment'
import { getRandomInRange } from 'application/utils/getRandomInRange'

import { getOutletAppliedParameter } from '../outlet/outletActions'

export const getCarTypeByClass = (
  car: Car,
  types: Array<{ id: number; name: string; classes: Array<string> }>,
  outlet: Outlet | null,
) => {
  const parameter = getOutletAppliedParameter(outlet, 'edited_car_classes')
  let classChar = car && car.class ? car.class.toUpperCase() : 'A'
  if (parameter) {
    try {
      const editedCarClasses = JSON.parse(parameter.value)
      if (
        editedCarClasses[`${car.name.toUpperCase()}`] &&
        editedCarClasses[`${car.name.toUpperCase()}`][`${car.modelName.toUpperCase()}`]
      ) {
        classChar = editedCarClasses[`${car.name.toUpperCase()}`][`${car.modelName.toUpperCase()}`]
      }
    } catch (e) {
      console.log(e)
    }
  }
  const type = _find(types, (item) => item.classes.indexOf(classChar) !== -1)
  return type ? type : types[0]
}

export const useCarTypes = (outlet: Outlet | null) => {
  const [carTypes, setCarTypes] =
    useState<Array<{ id: number; name: string; classes: Array<string> }>>(defaultCarTypes)

  useEffect(() => {
    const parameter = getOutletAppliedParameter(outlet, 'car_classes')
    if (parameter) {
      try {
        const res: Array<{ id: number; name: string; classes: Array<string> }> = []
        const parsed = JSON.parse(parameter.value)
        if (parsed && parsed.Categories) {
          let id = 1
          _mapKeys(parsed.Categories, (value: any, key: string) => {
            res.push({
              id,
              name: key,
              classes: value,
            })
            id += 1
          })
          setCarTypes(res)
        }
      } catch (e) {
        console.log(e)
      }
    }
  }, [outlet])

  return carTypes
}

export const formatStringToCarNumber = (carNumber: string) => {
  const formattedNumber = carNumber.replace(/ /g, '')
  const n = formattedNumber.length === 9 ? 3 : 2
  const region = formattedNumber.substring(formattedNumber.length - n)
  const number = formattedNumber.slice(0, formattedNumber.length - n)

  const splittedNumber = number.split('')
  splittedNumber[0] = splittedNumber[0] + ' '
  splittedNumber[3] = splittedNumber[3] + ' '
  const formated = splittedNumber.join('')
  return `${formated} ${region}`.toUpperCase()
}

type GenerateCarModelProps = {
  carId: string | number | undefined
  modelId: any
  carNumber: string
}

export const generateCarModel = ({
  carId,
  modelId,
  carNumber,
}: GenerateCarModelProps): Car | null => {
  const findedCar = _find(carsDB, (item) => item.id === carId)
  if (findedCar) {
    const selectedCar = _find(findedCar.models, (item) => item.id === modelId)
    if (selectedCar) {
      const formattedNumber = carNumber.replace(/\s/g, '').replace(/_/g, '')
      const n = formattedNumber.length === 9 ? 3 : 2
      const region = formattedNumber.substring(formattedNumber.length - n)
      const number = formattedNumber.slice(0, formattedNumber.length - n)
      const carModel: Car = {
        id: parseInt(`${moment().unix()}${getRandomInRange(1, 1000)}`, 10),
        name: `${carId}`,
        carId: `${carId}`,
        carName: `${findedCar.name}`,
        modelName: `${selectedCar.name}`,
        modelId: `${modelId}`,
        number: number,
        regionNumber: region,
        class: selectedCar.class,
        cyrillic_name: selectedCar['cyrillic-name'],
        year_from: selectedCar['year-from'],
        year_to: selectedCar['year-to'] || undefined,
      }
      return carModel
    }
  }
  return null
}
