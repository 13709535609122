import { changeOrderStatusRequest, updateOrderItemRequest } from 'application/data/api/order'
import queryClient from 'application/data/apiClient/queryClient'
import { ChangeOrderStatusDataType, UpdateItemDataType } from 'application/domain/types/order'
import { getOrderNewStatus } from 'application/presentation/common/PureFunctions/getOrderStatus'
import _ from 'lodash'
import moment from 'moment'
import { useMutation } from 'react-query'

export const changeOrderStatus = ({ open_order, status }: ChangeOrderStatusDataType) =>
  changeOrderStatusRequest({
    ...open_order,
    status: status || open_order.status,
    order_id: open_order.order_id || open_order.id,
  })

export const ChangeOrderStatusMutation = (callback?: Function, onError?: Function) => {
  const mutation = useMutation(
    ({ open_order, status }: ChangeOrderStatusDataType & { onRequestSettled?: Function }) =>
      changeOrderStatusRequest({ ...open_order, status: status || open_order.status }),
    {
      onMutate: async ({ open_order, status, onRequestSettled }) => {
        await queryClient.cancelQueries('lastOrders')
        const previousData = queryClient.getQueryData<any>('lastOrders')
        if (previousData) {
          const newStatus = getOrderNewStatus(status || open_order.status)
          const newData = _.cloneDeep(previousData)

          queryClient.setQueryData(
            'lastOrders',
            newData.map((item: any) => {
              item.map((item2: any) => {
                if (item2.open_order_id === open_order.open_order_id) {
                  item2.status = newStatus.rusStatus
                }
                return item2
              })
              return item
            }),
          )

          if (callback) {
            callback()
          }
        }
        return { previousData }
      },
      onError: (err, variables, context: any) => {
        if (context?.previousData) {
          queryClient.setQueryData('lastOrders', context.previousData)
          if (onError) {
            onError(err)
          }
        }
      },
      onSettled: (data, error, variables) => {
        queryClient.invalidateQueries('lastOrders')
        if (variables.onRequestSettled) {
          variables.onRequestSettled()
        }
      },
    },
  )
  return mutation
}

export const UpdateOrderItemMutation = (callback?: Function) => {
  const mutation = useMutation(
    ({ orderItemId, method }: UpdateItemDataType) => updateOrderItemRequest(orderItemId, method),
    {
      onMutate: async ({ orderItemId }) => {
        await queryClient.cancelQueries('lastOrders')
        const previousData = queryClient.getQueryData<any>('lastOrders')
        if (previousData) {
          let newData = _.cloneDeep(previousData)
          let newDataChangedElem = null
          newData = newData.map((item: any) => {
            item.map((item2: any) => {
              if (item2.id === orderItemId) {
                item2.updated_at = moment().format('YYYY-MM-DD HH:mm:ss')
                newDataChangedElem = item
              }
              return item2
            })
            return item
          })
          queryClient.setQueryData('lastOrders', newData)

          if (callback) {
            callback(newDataChangedElem)
          }
        }
        return { previousData }
      },
      onError: (err, variables, context: any) => {
        if (context?.previousData) {
          queryClient.setQueryData('lastOrders', context.previousData)
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('lastOrders')
      },
    },
  )
  return mutation
}
