/* eslint-disable @typescript-eslint/naming-convention */
import { Product } from 'application/domain/entity/product/Product'
import { RootState } from 'application/domain/store/store'
import { useProduct } from 'application/domain/useCase/products/products'
import { getDefaultOutlet } from 'application/presentation/UserController'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import { ReactComponent as CloseSvg } from 'images/icons/white_close_icon.svg'

import Loader from '../../Loader'
import { Button } from '../../uiComponents'
import MWContainer from '../MWContainer'

import EditAvailabilityContent from './EditAvailabilityContent'
import s from './EditProductAvailabilityMW.module.scss'

const EditProductAvailabilityMW = () => {
  const navigate = useNavigate()
  const { hash, search } = useLocation()
  const { user } = useSelector(({ userReducer }: RootState) => ({
    user: userReducer.user,
  }))
  const opened = useMemo(() => hash.indexOf('edit_availability') !== -1, [hash])

  const product_id = useMemo(() => {
    const searchParams = new URLSearchParams(search)
    const id = searchParams.get('product_id')
    return parseInt(id ? id : '0', 10)
  }, [search])

  const outlet_id = useMemo(() => getDefaultOutlet(user), [user])

  const { data, isLoading } = useProduct(product_id, outlet_id, {
    enabled: opened && product_id !== 0 ? true : false,
    refetchOnWindowFocus: false,
  })

  const product: Product = useMemo(() => {
    return data && data.product ? data.product : null
  }, [data])

  return (
    <MWContainer
      isOpen={opened}
      onRequestClose={() => {
        if (hash.indexOf('product') !== -1) {
          navigate(-1)
        }
      }}
      className={`${s.modal_window_desk}`}
      btnClassName={s.close_btn}
    >
      <div className={s.container}>
        <div className={s.sticky}>
          <div className={s.custom_close_btn} onClick={() => navigate(-1)}>
            <CloseSvg />
          </div>
          <div className={s.title}>Редактирование наличия</div>
        </div>
        {isLoading ? (
          <Loader color={'#ffffff'} />
        ) : product ? (
          <EditAvailabilityContent product={product} outletId={outlet_id} />
        ) : (
          <div>Товар не найден</div>
        )}
      </div>
    </MWContainer>
  )
}

export default React.memo(EditProductAvailabilityMW)
